import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { retryAsync } from '@/helpers/retry'
import { publicAxios, streamLadderAxiosInstance } from '@/services/axios'
import Pusher from 'pusher-js'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { HttpStatusCode } from 'axios'

const PUSHER_APP_KEY = 'ef0a10b651ed4adf46eb'

export function useCensor() {
  const isCensoring = ref(false)

  const editorClipInfoStore = useEditorClipInfoStore()
  const captionsStore = useCaptionsStore()

  const pusherClient = new Pusher(PUSHER_APP_KEY, {
    cluster: 'us3'
  })

  onUnmounted(() => {
    for (const channel of pusherClient.allChannels()) {
      channel.unsubscribe()
      channel.disconnect()
    }
  })

  return {
    isCensoring,
    censorCurseWords
  }

  async function censorCurseWords(transcript: string, { force }: { force?: boolean } = { force: false }) {
    if (!transcript || transcript.length === 0) {
      return
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      if (!editorClipInfoStore.mp4Url) {
        reject('No mp4Url found')
        return
      }

      isCensoring.value = true

      try {
        const result = await retryAsync(async () => {
          return await streamLadderAxiosInstance<CensorResponse>({
            url: '/api/Captions/Censor',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
              transcript: transcript,
              skipCache: force,
            }
          })
        })

        if (result.fullFilePath) {
          await markCurseWords(result.fullFilePath)
        } else {
          await listenForCensoring(result.pusherChannelId)
        }

        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      } finally {
        isCensoring.value = false
      }
    })
  }

  async function listenForCensoring(pusherChannelId: string) {

    const channel = pusherClient.subscribe(pusherChannelId)

    return new Promise<void>((resolve, reject) => {
      channel.bind('finished', async (data: { fullFilePath: string }) => {
        await markCurseWords(data.fullFilePath)

        channel.unsubscribe();
        channel.disconnect();

        resolve()
      })

      channel.bind('error', (data: { message: string }) => {
        console.error(data)

        channel.unsubscribe();
        channel.disconnect();

        reject(new Error(`Error while listening for Censor to finish. ${data.message}`))
      })
    })
  }

  async function markCurseWords(fullFilePath: string) {
    const response = await publicAxios.get<string>(fullFilePath)

    if (response.status !== HttpStatusCode.Ok) {
      throw new Error(`Error while downloading the file from Censor. Status code: ${response.status}`)
    }

    if (!response.data || response.data.length === 0) {
      return
    }

    for (const entity of captionsStore.entities) {
      for (const word of entity.words) {
        word.isCurseWord = response.data.includes(word.text)
      }
    }
  }
}

interface CensorResponse {
  fileName: string
  fullFilePath: string
  taskId: string
  pusherChannelId: string
}
