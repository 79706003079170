import { supabase, getUser } from '@/authentication/supabase';
import type { Project } from '@/areas/editor/@type/Project';
import { version } from '@/data/versions';
import type { DeepReadonly } from 'vue';
import { retryAsync } from '@/helpers/retry';
import type { EditorProject } from '@/areas/editor/@type/editor-project';

export async function storeProject(project: DeepReadonly<Project | EditorProject>) {
  try {
    const response = await storeProjectInDb(project);
    if (!response.ok) {
      await storeProjectInLocalStorage(project);
    }
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save project.');
  }
}

export async function storeProjectInDb(project: DeepReadonly<Project | EditorProject>) {

  const { data: { user } } = await getUser();
  if (!user) {
    return { ok: false };
  }

  if (!project.id) {
    throw new Error('Project ID is required.');
  }

  const response = await supabase.from('ClipEditorProjects').upsert({
    Id: project.id,
    UserId: user.id,
    CreatedAt: new Date().toISOString(),
    Version: 'save-projects-in-supabase',
    Json: project
  });

  if (response.error) {
    if (response.error.message === 'new row violates row-level security policy (USING expression) for table "ClipEditorProjects"') {
      console.warn('User does not have permission to save project.');
      return { ok: false };
    } else {
      throw new Error(response.error.message);
    }
  }

  return { ok: true };
}

async function storeProjectInLocalStorage(project: DeepReadonly<Project | EditorProject>) {
  localStorage.setItem(`/${project.id}/${version}/project.json`, JSON.stringify(project));
}

export async function fetchProjectJson(id: string) {

  try {

    const response = await retryAsync(() => fetchProjectJsonFromSupabase(id));
    if (response.ok) {
      return response.data;
    }

    return await fetchProjectJsonFromLocalStorage(id);
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load project.');
  }
}

async function fetchProjectJsonFromSupabase(id: string): Promise<{ ok: false, data: null } | { ok: true, data: Project }> {

  // If user is not logged in then there is no need to fetch project from database.
  const { data: { user } } = await getUser();
  if (!user) {
    return { ok: false, data: null };
  }

  const { data, error } = await retryAsync(async () => {
    return supabase.from('ClipEditorProjects')
      .select(`
        json:     Json, 
        version:  Version
     `)
      .eq('Id', id)
      .single();
  });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return { ok: false, data: null };
  }

  return { ok: true, data: data.json as unknown as Complete<Project> };
}

async function fetchProjectJsonFromLocalStorage(id: string) {

  const item = localStorage.getItem(`/${id}/${version}/project.json`);
  if (!item) {
    throw new Error('Project not found.');
  }

  return JSON.parse(item) as Complete<Project>;
}

type Complete<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>;
};
