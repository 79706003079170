import { useMediaControls, useRafFn } from '@vueuse/core'
import { computed, ref, type Ref } from 'vue'
import { sumBy, orderBy, noop, round, clamp } from 'lodash-es';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

function durationOf(segment: { startMs: number; endMs: number }) {
  return segment.endMs - segment.startMs
}

export const useSLVideoController = (video: Ref<HTMLVideoElement | undefined>, segments: Ref<{ startMs: number; endMs: number }[]>) => {

  const controls = useMediaControls(video)

  const captionsStore = useCaptionsStore();
  const words = computed(() => captionsStore.entities.flatMap((e) => e.words));

  const durationMs = computed(() => controls.duration.value * 1000)
  const currentTimeMs = ref(0)

  const currentTimeBindings = (() => {

    const min = ref(0)
    const max = durationMs
    const value = computed({
      get: () => currentTimeMs.value,
      set: (value) => {
        currentTimeMs.value = Number(value)
        controls.currentTime.value = Number(value) / 1000
      },
    })

    return {
      min: min,
      max: max,
      step: 0.001,
      model: value,
      progress: computed(() => round((value.value - min.value) / (max.value - min.value), 3) * 100),
      onMouseDown: noop,
      onMouseUp: noop,
    }
  })()

  const segmentsWithOutputTimestamps = computed(() => (segments.value ?? []).map((segment, i) => {
    const segmentsBefore = (segments.value ?? []).slice(0, i)
    const outputStartMs = sumBy(segmentsBefore, s => durationOf(s))
    const outputEndMs = outputStartMs + durationOf(segment)
    return {
      ...segment,
      outputStartMs,
      outputEndMs
    }
  }))

  const userMute = ref(false)
  const forceMute = ref(false)

  watch([userMute, forceMute], () => {
    controls.muted.value = userMute.value || forceMute.value
  })

  const sortedSegments = computed(() => orderBy(segmentsWithOutputTimestamps.value, 'startMs', 'asc'))

  useRafFn(async () => {

    if (!video.value) {
      return
    }

    currentTimeMs.value = video.value.currentTime * 1000
    
    if (video.value.paused || video.value.seeking) {
      return
    }
    
    const segments = sortedSegments.value
    if (segments.length === 0) {
      return;
    }

    const index = segments.findIndex((segment) => {
      return currentTimeMs.value >= segment.startMs && currentTimeMs.value <= segment.endMs
    })

    // if end of segment or almost end of video (100 ms)
    if (index === -1 || Math.abs(video.value.currentTime - video.value.duration) < .1) {
      // Either the cut to the next segment or loop back around to the first segment.
      const next = Math.max(0, segments.findIndex(s => currentTimeMs.value < s.startMs))
      video.value.currentTime = segments[next].startMs / 1000
    }

    if (captionsStore.bleepCurseWords) {
      const currentWord = words.value.find(
        (w) => w.start <= currentTimeMs.value && w.end > currentTimeMs.value
      );
      forceMute.value = (currentWord && currentWord.isCurseWord) ?? userMute.value
    } else {
      forceMute.value = false;
    }
  })

  // The sum of all the segments in the clip. 
  const outputDurationMs = computed(() => {
    return sumBy(sortedSegments.value, segment => segment.endMs - segment.startMs)
  })

  // Represents the current time in the context of the output video. For example, if there are two segments (0-500ms 
  // and 1000-1500ms) and the video is at 1250ms, the outputCurrentTimeMs will be 750ms.
  const outputCurrentTimeMs = computed({
    get() {
      return sumBy(sortedSegments.value, segment => {
        return clamp(currentTimeMs.value - segment.startMs, 0, segment.endMs - segment.startMs)
      })
    },
    set(value: number) {
  
      if (!video.value) {
        return
      }
  
      for (const segment of sortedSegments.value) {
        if (value >= segment.outputStartMs && value <= segment.outputEndMs) {
          setTimeout(() => {
            if (video.value) {
              video.value.currentTime = (segment.startMs + value - segment.outputStartMs) / 1000
            }
          }, 0)
        }
      }
    }
  })

  return {

    ...controls,

    duration: computed(() => outputDurationMs.value / 1000),
    _duration: controls.duration,
    durationMs: durationMs,

    currentTime: computed(() => currentTimeMs.value),
    _currentTime: controls.currentTime,
    currentTimeMs: currentTimeMs,

    preciseCurrentTime: computed(() => currentTimeMs.value / 1000),

    currentTimeBindings,

    muted: userMute,
  }
}
