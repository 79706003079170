import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

export const captionBaseStylesSettings = {
  lit: {
    previewSettings: {
      displayName: 'Lit!',
      scaling: 1.3,
      previewText: [
        'Lit',
        'Head-turning',
        'Unleash',
        'the',
        'Lit',
        'style',
        'which',
        'is',
        'as',
        'bold',
        'as',
        'it',
        'is',
        'head-turning',
        'like',
        'walking',
        'into',
        'a',
        'board',
        'meeting',
        'with',
        'a',
        'superhero',
        'cape.'
      ]
    },
    createdAt: new Date(2024, 5, 20),
    cssClass: 'epic-gamer uppercase',
    fontFamily: 'Anton',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#000000',
        width: 4
      },
      color: 'default'
    },
    colors: [
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ffff00',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff'
    ],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['popular', 'playful', 'simple']
  } as CaptionStyleDefinition,
  funky: {
    previewSettings: {
      displayName: 'Beast!',
      scaling: 1.3,
      previewText: [
        'Beast',
        'Exuberant',
        'Release',
        'your',
        'inner',
        'disco',
        'with',
        'the',
        'Funky',
        'style',
        'as',
        'playful',
        'and',
        'exuberant',
        'as',
        'a',
        'peacock',
        'doing',
        'the',
        'moonwalk.'
      ]
    },
    createdAt: new Date(2024, 5, 17),
    cssClass: 'funky uppercase',
    fontFamily: 'Bangers',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#000000',
        width: 3
      },
      color: 'default'
    },
    colors: [
      '#FFFFFF',
      '#F5FFFA',
      '#E8E8E8',
      '#ffe5b4',
      '#fff5cf',
      '#ffff00',
      '#f0e68c',
      '#ffd700',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57'
    ],
    highlightColor: '#fc5b5b',
    effects: {},
    tags: ['popular']
  } as CaptionStyleDefinition,
  ninja: {
    previewSettings: {
      displayName: 'Ninja!',
      scaling: 1.3,
      previewText: [
        'Ninja! 🥷',
        'Stealthy',
        'Embrace',
        'the',
        'Ninja!',
        'style',
        'as',
        'stealthy',
        'and',
        'mysterious',
        'as',
        'a',
        'ninja'
      ]
    },
    createdAt: new Date(2024, 5, 14),
    cssClass: 'uppercase',
    fontFamily: 'Sovereign',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-55',
        width: 3
      },
      dropShadow: {
        color: '-70',
        offset: {
          x: 4,
          y: 4
        },
        blur: 0
      },
      color: 'default'
    },
    colors: [
      '#00F5D4',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['playful', 'popular']
  } as CaptionStyleDefinition,
  'battle royale': {
    previewSettings: {
      displayName: 'battle royale',
      scaling: 1.3,
      previewText: ['battle royale', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 16),
    cssClass: 'uppercase',
    fontFamily: 'Fortnite',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-80',
        width: 3
      },
      color: 'default',
      dropShadow: {
        color: '-40',
        offset: {
          x: 4,
          y: 4
        },
        blur: 5,
        opacity: 0.5
      }
    },
    colors: [
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['playful', 'popular']
  } as CaptionStyleDefinition,
  'hype-train': {
    previewSettings: {
      displayName: 'Hype train',
      scaling: 1.3,
      previewText: [
        'Hype',
        'train',
        'is',
        'rolling',
        'Eye-Catching',
        'Welcome',
        'aboard',
        'the',
        'Hype',
        'train',
        'style',
        'all',
        'aboard',
        'for',
        'bright',
        'colors',
        'and',
        'a',
        'radiant',
        'glow',
        'that',
        'is',
        'as',
        'eye-catching',
        'as',
        'a',
        'comet',
        'streaking',
        'through',
        'the',
        'night',
        'sky.'
      ]
    },
    createdAt: new Date(2024, 5, 19),
    cssClass: 'hype-train uppercase',
    fontFamily: 'Bungee',
    fontSize: {
      fontSize: 30,
      lineHeight: 1.1
    },
    style: {
      border: {
        color: 'darker',
        width: 3
      },
      shadow: {
        color: 'darker',
        width: 20
      },
      color: 'default'
    },
    colors: [
      '#9B5DE5',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ffff00',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#FFE500',
    effects: {},
    tags: ['playful']
  } as CaptionStyleDefinition,
  puff: {
    previewSettings: {
      displayName: 'Puff!',
      scaling: 1.6,
      previewText: [
        'Puff',
        'Joyful',
        'Embrace',
        'the',
        'Puff',
        'style',
        'a',
        'bouncy',
        'colorful',
        'spectacle',
        'that',
        'is',
        'as',
        'playful',
        'and',
        'joyful',
        'as',
        'a',
        'kid',
        'with',
        'a',
        'new',
        'toy',
        'on',
        'Christmas',
        'morning.'
      ]
    },
    createdAt: new Date(2024, 5, 18),
    cssClass: 'puff uppercase',
    fontFamily: 'DynaPuff',
    fontSize: {
      fontSize: 30
    },
    style: {
      border: {
        color: 'darker',
        width: 4
      },
      color: 'default'
    },
    colors: [
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ffff00',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff'
    ],
    highlightColor: '#00ff57',
    effects: {},
    tags: ['popular', 'playful']
  } as CaptionStyleDefinition,
  mreast: {
    previewSettings: {
      displayName: 'Mreast',
      scaling: 1.6,
      previewText: [
        'Mreast',
        'west',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 7, 5),
    cssClass: 'uppercase',
    fontFamily: 'Komika Axis',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: '-95',
        width: 3
      },
      dropShadow: {
        color: '+30',
        offset: { x: 0, y: 0 },
        blur: 5,
        opacity: 0.5
      }
    },
    colors: [
      '#ffffff',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['simple']
  } as CaptionStyleDefinition,
  'noto': {
    previewSettings: {
      displayName: 'Noto',
      scaling: 1.3,
      previewText: ['Noto', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 16),
    cssClass: 'uppercase',
    fontFamily: 'Noto Black',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-100',
        width: 3
      },
      color: 'default',
      dropShadow: {
        color: '-40',
        offset: {
          x: 4,
          y: 4
        },
        blur: 5,
        opacity: 0.5
      }
    },
    colors: [
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['playful', 'popular', 'global']
  } as CaptionStyleDefinition,
  duckie: {
    previewSettings: {
      displayName: 'Duckie',
      scaling: 1.3,
      previewText: ['Duckie', 'Quirky', 'Get', 'a', 'quack', 'out', 'of', 'Duckie', 'style']
    },
    createdAt: new Date(2024, 5, 4),
    cssClass: 'uppercase',
    fontFamily: 'Luckiest Guy',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#373737',
        width: 4
      },
      dropShadow: {
        color: '#000000',
        offset: { x: 2, y: 2 },
        blur: 1
      },
      color: 'default'
    },
    colors: [
      '#fff5cf',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57'
    ],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['playful', 'popular', 'simple']
  } as CaptionStyleDefinition,
  chuckle: {
    previewSettings: {
      displayName: 'Chuckle',
      scaling: 1.6,
      previewText: [
        'Chuckle',
        'Entertaining',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 5, 5),
    cssClass: 'chuckle',
    fontFamily: 'Metropolis-bold',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: '#000000',
        width: 2
      }
    },
    colors: [
      '#00BBF9',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['simple']
  } as CaptionStyleDefinition,
  'big glow': {
    previewSettings: {
      displayName: 'Big glow',
      scaling: 1.3,
      previewText: ['Big glow', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 9),
    cssClass: 'capitalize',
    fontFamily: 'Luckiest Guy',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#373737',
        width: 3
      },
      color: 'default',
      dropShadow: {
        color: 'default',
        blur: 9
      }
    },
    colors: [
      '#00ffff',
      '#a4ffff',
      '#00b4b4',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffff00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['playful']
  } as CaptionStyleDefinition,
  'gaming yuu': {
    previewSettings: {
      displayName: 'gaming yuu!',
      scaling: 1.3,
      previewText: [
        'Gaming yuu!',
        'Gaming',
        'Embrace',
        'the',
        'Gaming yuu!',
        'style',
        'like',
        'a',
        'gamer',
        'on',
        'a',
        'quest'
      ]
    },
    createdAt: new Date(2024, 5, 2),
    cssClass: 'capitalize',
    fontFamily: 'Spot',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#3D3D3D',
        width: 3
      },
      color: 'default',
      dropShadow: {
        color: '#000000',
        offset: { x: 1, y: 1 },
        blur: 0,
        opacity: 0.6
      }
    },
    colors: [
      '#ffffff',
      '#EBFF00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['playful']
  } as CaptionStyleDefinition,
  bleepiee: {
    previewSettings: {
      displayName: 'Bleepiee',
      scaling: 1.6,
      previewText: [
        'What a cool\n' +
        'story',
        'Boppin',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 7, 5),
    cssClass: 'uppercase',
    fontFamily: 'Cherry Bomb',
    fontSize: {
      fontSize: 28,
      fontWeight: 900,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: '-90',
        width: 2
      },
      dropShadow: {
        color: 'black',
        offset: { x: 0, y: 4 },
        blur: 3,
        opacity: 0.8
      }
    },
    colors: [
      '#FFFFFF',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['playful']
  } as CaptionStyleDefinition,
  'swag city': {
    previewSettings: {
      displayName: 'swag city',
      scaling: 1.3,
      previewText: ['swag city', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 13),
    cssClass: 'capitalize',
    fontFamily: 'Daddy Ranch',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '#3D3D3D',
        width: 2
      },
      color: 'default',
      dropShadow: {
        color: '#000000',
        offset: { x: 2, y: 2 },
        blur: 3
      }
    },
    colors: [
      '#ff78ff',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#ff0000',
    effects: {},
    tags: ['playful', 'simple']
  } as CaptionStyleDefinition,
  'bold-glow': {
    previewSettings: {
      displayName: 'Glowzilla',
      scaling: 1,
      previewText: [
        'Bold',
        'glow',
        'Lustrous',
        'Stand',
        'out',
        'from',
        'the',
        'crowd',
        'with',
        'Bold',
        'glow',
        'style',
        'as',
        'lustrous',
        'as',
        'a',
        'diamond',
        'ring',
        'in',
        'a',
        'treasure',
        'chest.'
      ]
    },
    createdAt: new Date(2024, 5, 12),
    cssClass: 'uppercase',
    fontFamily: 'Metropolis-extra-bold',
    fontSize: {
      fontSize: 38,
      lineHeight: 0.9
      // fontWeight: 800,
    },
    style: {
      border: {
        color: 'darker',
        width: 2
      },
      shadow: {
        color: 'darker',
        width: 10
      },
      color: 'default'
    },
    colors: [
      '#FEE440',
      '#ffffff',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffff00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#b5d3ff',
    effects: {},
    tags: ['playful']
  } as CaptionStyleDefinition,
  wasted: {
    previewSettings: {
      displayName: 'Wasted',
      scaling: 1.6,
      previewText: [
        'Wasted',
        'Intense',
        'Make',
        'a',
        'statement',
        'with',
        'the',
        'Wasted',
        'style',
        'as',
        'bold',
        'and',
        'intense',
        'as',
        'a',
        'cowboy',
        'entering',
        'a',
        'saloon',
        'with',
        'spurs',
        'jingling.'
      ]
    },
    createdAt: new Date(2024, 5, 7),
    cssClass: 'WoodenNickelNF uppercase',
    fontFamily: 'WoodenNickelNF',
    fontSize: {
      fontSize: 30,
      lineHeight: 1
    },
    style: {
      border: {
        color: 'darker',
        width: 2
      },
      shadow: {
        color: 'darker',
        width: 10
      },
      color: 'default'
    },
    colors: [
      '#ff4400',
      '#ffffff',
      '#00ff00',
      '#86ff86',
      '#ccffcc',
      '#00ff57',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffff00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#00ff00',
    effects: {}
  } as CaptionStyleDefinition,
  'big slick': {
    previewSettings: {
      displayName: 'Big slick',
      scaling: 1.3,
      previewText: ['Big slick', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 8),
    cssClass: 'uppercase',
    fontFamily: 'Pedlefun',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-50',
        width: 1.3
      },
      color: 'default',
      dropShadow: {
        color: '-40',
        offset: {
          x: 0,
          y: 0
        },
        blur: 12,
        opacity: 0.8
      }
    },
    colors: [
      '#F15BB5',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffff00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#ffa500',
    effects: {},
    tags: ['playful']
  } as CaptionStyleDefinition,
  neon: {
    previewSettings: {
      displayName: 'Neon Style',
      scaling: 1.3,
      previewText: [
        'Neon',
        'Style',
        'Luminous',
        'Turn',
        'the',
        'night',
        'into',
        'day',
        'with',
        'Neon',
        'Style',
        'as',
        'bold',
        'and',
        'luminous',
        'as',
        'a',
        'city',
        'skyline',
        'during',
        'a',
        'festival',
        'of',
        'lights.'
      ]
    },
    createdAt: new Date(2024, 5, 15),
    cssClass: 'neon uppercase',
    fontFamily: 'Sigmar One',
    fontSize: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: 'darker',
        width: 3
      },
      shadow: {
        color: 'darker',
        width: 20
      }
    },
    colors: [
      '#c9ff42',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ffff00',
      '#ffe5b4',
      '#fff5cf',
      '#f0e68c',
      '#ffd700',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#b600b6',
    effects: {}
  } as CaptionStyleDefinition,
  noir: {
    previewSettings: {
      lottieUrl: '',
      displayName: 'Fun',
      scaling: 1.5,
      previewText: ['Fun', 'Dive', 'into', 'FUN', 'and', 'watch', 'the', 'magic ✨', 'happen']
    },
    createdAt: new Date(2024, 5, 10),
    fontFamily: 'Noir Pro',
    fontSize: {
      fontSize: 29,
      lineHeight: 1.375
    },
    cssClass: 'uppercase',
    style: {
      border: {
        color: '#99325F',
        width: 1
      },
      dropShadow: {
        color: '#32101F',
        offset: { x: 0, y: 0 },
        blur: 5,
        opacity: 0.5
      },
      color: 'default'
    },
    colors: ['#FFC700', '#E087FF', '#87FF93', '#00B728', '#F9D828', '#009245', '#EE9CA7'],
    highlightColor: '#E087FF',
    gradients: {
      '#FFC700': 'linear-gradient(to bottom, #E087FF, #FFC700)',
      '#E087FF': 'linear-gradient(to bottom, #00F0FF, #E087FF)',
      '#87FF93': 'linear-gradient(to top, #00E0FF, #87FF93)',
      '#009245': 'radial-gradient(circle, #FCEE21, #009245)',
      '#EE9CA7': 'radial-gradient(circle, #FFDDE1, #EE9CA7)',
      '#00B728': 'linear-gradient(to bottom, #28F9D3, #00B728)',
      '#F9D828': 'linear-gradient(to bottom, #F92828, #F9D828)'
    },
    borderColors: {
      '#E087FF': '#99325F',
      '#FFC700': '#99325F',
      '#87FF93': '#99325F',
      '#009245': '#097600',
      '#EE9CA7': '#6F2F46',
      '#00B728': '#097600',
      '#F9D828': '#99325F'
    },
    tags: ['popular', 'playful'],
    effects: {},
    disableHighlight: false
  },
  acme: {
    previewSettings: {
      displayName: 'Acme play',
      scaling: 1.6,
      previewText: [
        'Acme play',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 7, 5),
    cssClass: '',
    fontFamily: 'Acme',
    fontSize: {
      fontSize: 30,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: 'black',
        width: 3
      },
      dropShadow: {
        color: 'black',
        offset: { x: 0, y: 0 },
        blur: 5,
        opacity: 0.8
      }
    },
    colors: [
      '#FFF500',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['playful']
  } as CaptionStyleDefinition,
  'flash fail': {
    previewSettings: {
      displayName: 'Flash Fail',
      scaling: 1.3,
      previewText: ['Flash Fail', 'Press D', 'Instead of F', 'with style', 'like a', 'Bronze boy']
    },
    createdAt: new Date(2024, 5, 11),
    cssClass: 'uppercase',
    fontFamily: 'Beaufort',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-40',
        width: 1
      },
      color: 'default',
      dropShadow: {
        color: '-40',
        offset: {
          x: 0,
          y: 0
        },
        blur: 12,
        opacity: 0.8
      }
    },
    colors: [
      '#ff7f50',
      '#E7E0B2',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ffb99f',
      '#ffa500',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#43D2FE'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  'retro-gold': {
    previewSettings: {
      displayName: 'Retro Gold',
      scaling: 1.6,
      previewText: [
        'Retro Gold',
        'Stylin',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 7, 5),
    cssClass: 'uppercase',
    fontFamily: 'Bevan',
    fontSize: {
      fontSize: 30,
      fontWeight: 800,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: '-95',
        width: 4
      }
    },
    colors: [
      '#EB00FF',
      '#FFE500',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#FFE500',
    effects: {
      textTransform: 'none'
    },
    tags: ['simple']
  } as CaptionStyleDefinition,
  simplistico: {
    previewSettings: {
      displayName: 'Simplistico',
      scaling: 1.6,
      previewText: [
        'Simplistico',
        'Simply',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 7, 5),
    cssClass: '',
    fontFamily: 'Space Grotesk',
    fontSize: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default',
      dropShadow: {
        color: 'black',
        offset: { x: 0, y: 0 },
        blur: 2,
        opacity: 0.8
      }
    },
    colors: [
      '#FFFFFF',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['simple']
  } as CaptionStyleDefinition,
  kappa: {
    previewSettings: {
      displayName: 'kappa',
      scaling: 1,
      previewText: [
        'Kappa',
        'Noteworthy',
        'Dive',
        'into',
        'the',
        'color',
        'pool',
        'with',
        'Kappa',
        'style',
        'as',
        'distinctive',
        'and',
        'noteworthy'
      ]
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'kappa uppercase',
    fontFamily: 'Metropolis',
    fontSize: {
      fontSize: 30,
      lineHeight: 1.4
    },
    style: {
      color: 'default'
    },
    colors: [
      '#F15BB5',
      '#6891cc',
      '#2266cc',
      '#0051cc',
      '#0046cc',
      '#cc37cc',
      '#830083',
      '#cc00cc',
      '#cc1cba',
      '#332929',
      '#cca3a3',
      '#b59191',
      '#c92020',
      '#cc0000',
      '#cc3600',
      '#cc4817',
      '#cc7656',
      '#cc8400',
      '#cca967',
      '#ccbc7d',
      '#ccac00',
      '#bdb148',
      '#cccc00',
      '#7acc7a',
      '#42cc42',
      '#00cc00',
      '#00cc46',
      '#9bccb4',
      '#00cca7',
      '#5acccc',
      '#008181',
      '#00cccc'
    ],
    highlightColor: '#5227FF',
    disableHighlight: true,
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  basic: {
    previewSettings: {
      displayName: 'Basic',
      previewText: [
        'Basic',
        'Easy-going',
        'Keep',
        'it',
        'real',
        'with',
        'Basic',
        'style',
        'as',
        'simple',
        'and',
        'easy-going',
        'as',
        'a',
        'zen',
        'monk',
        'on',
        'a',
        'Sunday',
        'stroll.'
      ],
      scaling: 1.6
    },
    createdAt: new Date(2024, 5, 6),
    cssClass: 'basic',
    fontFamily: 'Metropolis-bold',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default'
    },
    colors: [
      '#000000',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#ff0000',
    effects: {
      textTransform: 'none'
    },
    tags: ['popular', 'simple']
  } as CaptionStyleDefinition,
  quirkonia: {
    previewSettings: {
      displayName: 'Quirkonia',
      scaling: 1.3,
      previewText: [
        'Quirkonia',
        'Quirky',
        'Embrace',
        'the',
        'Quirkonia',
        'style',
        'as',
        'quirky',
        'and',
        'fun',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 5, 3),
    cssClass: '',
    fontFamily: 'Francy',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-55',
        width: 2
      },
      color: 'default',
      dropShadow: {
        color: '-40',
        offset: {
          x: 0,
          y: 0
        },
        blur: 5,
        opacity: 0.5
      }
    },
    colors: [
      '#FEE440',
      '#E7E0B2',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#43D2FE'
    ],
    highlightColor: '#b5d3ff',
    tags: ['simple'],
    effects: {}
  } as CaptionStyleDefinition,
  'basic jan': {
    previewSettings: {
      displayName: 'Basic Jan',
      scaling: 1.3,
      previewText: ['Basic Jan', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city']
    },
    createdAt: new Date(2024, 5, 1),
    cssClass: '',
    fontFamily: 'Pulse',
    fontSize: {
      fontSize: 38
    },
    style: {
      border: {
        color: '-40',
        width: 2
      },
      color: 'default',
      dropShadow: {
        color: '-30',
        offset: {
          x: 2,
          y: 2
        },
        blur: 15,
        opacity: 0.6
      }
    },
    colors: [
      '#00F5D4',
      '#E7E0B2',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA',
      '#43D2FE'
    ],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  noop: {
    previewSettings: {
      displayName: 'Noop',
      scaling: 1,
      previewText: [
        'Noop',
        'Noteworthy',
        'Dive',
        'into',
        'the',
        'color',
        'pool',
        'with',
        'Kappa',
        'style',
        'as',
        'distinctive',
        'and',
        'noteworthy'
      ]
    },
    createdAt: new Date(2024, 5, 16),
    cssClass: 'kappa kappa-fade',
    fontFamily: 'Noto Black',
    fontSize: {
      fontSize: 30,
      lineHeight: 1.4
    },
    style: {
      color: 'default'
    },
    colors: [
      '#cc8400',
      '#6891cc',
      '#2266cc',
      '#0051cc',
      '#0046cc',
      '#cc37cc',
      '#830083',
      '#cc00cc',
      '#cc1cba',
      '#332929',
      '#cca3a3',
      '#b59191',
      '#c92020',
      '#cc0000',
      '#cc3600',
      '#cc4817',
      '#cc7656',
      '#cca967',
      '#ccbc7d',
      '#ccac00',
      '#bdb148',
      '#cccc00',
      '#7acc7a',
      '#42cc42',
      '#00cc00',
      '#00cc46',
      '#9bccb4',
      '#00cca7',
      '#5acccc',
      '#008181',
      '#00cccc'
    ],
    highlightColor: '#5227FF',
    disableHighlight: true,
    effects: {},
    tags: ['simple', 'global']
  } as CaptionStyleDefinition,
  sonoto: {
    previewSettings: {
      displayName: 'Sonoto',
      scaling: 1.6,
      previewText: [
        'Sonoto',
        'Entertaining',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.'
      ]
    },
    createdAt: new Date(2024, 5, 5),
    cssClass: '',
    fontFamily: 'Noto Bold',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default',
      border: {
        color: '-90',
        width: 1
      }
    },
    colors: [
      '#ffd700',
      '#ffe5b4',
      '#ffff00',
      '#fff5cf',
      '#f0e68c',
      '#00ffd1',
      '#a4ffff',
      '#00b4b4',
      '#00ffff',
      '#b5d3ff',
      '#5d9eff',
      '#1371ff',
      '#0057ff',
      '#ff78ff',
      '#b600b6',
      '#ff00ff',
      '#ff56ee',
      '#fc5b5b',
      '#ff0000',
      '#ff4400',
      '#ff7f50',
      '#ffb99f',
      '#ffa500',
      '#ccffcc',
      '#86ff86',
      '#00ff00',
      '#00ff57',
      '#FFFFFF',
      '#E8E8E8',
      '#F5FFFA'
    ],
    highlightColor: '#5d9eff',
    effects: {
      textTransform: 'none'
    },
    tags: ['simple', 'global']
  } as CaptionStyleDefinition,
  cryo: {
    previewSettings: {
      displayName: 'Cryo',
      scaling: 1.6,
      previewText: [
        'Cryo',
        'Chilled',
        'and',
        'precise.',
        'Stay',
        'cool',
        'under',
        'pressure.'
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: 'uppercase',
    fontFamily: 'Roboto Semi Condensed Black',
    fontSize: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1
    },
    style: {
      color: 'default'
    },
    colors: [
      '#ff82bc'
    ],
    highlightColor: '#b3f6ff',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  fresh: {
    previewSettings: {
      displayName: 'Fresh',
      scaling: 1.6,
      previewText: [
        'Fresh',
        "Crisp,",
        "clean,",
        "and",
        "ready",
        "to",
        "flex",
        "on",
        "the",
        "competition."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: 'uppercase',
    fontFamily: 'Roboto Semi Condensed Extra Bold Italic',
    fontSize: {
      fontSize: 30,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#87de6f',
        width: 10
      }
    },
    colors: [
      '#ffffff'
    ],
    highlightColor: '#00ff00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  ember: {
    previewSettings: {
      displayName: 'Ember',
      scaling: 1.6,
      previewText: [
        'Ember',
        "Burning",
        "bright.",
        "Turn",
        "up",
        "the",
        "heat",
        "and",
        "dominate."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: 'uppercase',
    fontFamily: 'Rubik Bold',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#b02300',
        width: 10
      }
    },
    colors: [
      '#ff9500'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  glow: {
    previewSettings: {
      displayName: 'Glow',
      scaling: 1.6,
      previewText: [
        'Glow',
        "Lit",
        "up",
        "and",
        "unstoppable.",
        "Shine",
        "through",
        "the",
        "chaos."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: 'uppercase',
    fontFamily: 'Gabarito Extra Bold',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#ffffff',
        width: 10
      }
    },
    colors: [
      '#ff9500'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  racing: {
    previewSettings: {
      displayName: 'Racing',
      scaling: 1.6,
      previewText: [
        'Racing',
        "Max",
        "speed.",
        "No",
        "brakes,",
        "no",
        "limits,",
        "just",
        "pure",
        "adrenaline."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: '',
    fontFamily: 'Racing Sans One',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#000000',
        width: 5
      }
    },
    colors: [
      '#ffffff'
    ],
    highlightColor: '#ffff00',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  blocky: {
    previewSettings: {
      displayName: 'Blocky',
      scaling: 1.6,
      previewText: [
        'Blocky',
        "Chunky,",
        "bold,",
        "and",
        "built",
        "to",
        "last.",
        "Break,",
        "build,",
        "repeat."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: '',
    fontFamily: 'MinePlayer',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#193006',
        width: 5
      }
    },
    colors: [
      '#81d938'
    ],
    highlightColor: '#fff314',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  turbo: {
    previewSettings: {
      displayName: 'Turbo',
      scaling: 1.6,
      previewText: [
        'Turbo',
        "Fast,",
        "flashy,",
        "and",
        "smooth.",
        "The",
        "perfect",
        "balance",
        "of",
        "speed",
        "and",
        "style."
      ]
    },
    createdAt: new Date(2025, 1, 25),
    cssClass: 'uppercase',
    fontFamily: 'Fira Sans Condensed Black Italic',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
      shadow: {
        color: '#abff66',
        width: 10
      },
    },
    colors: [
      '#abff66'
    ],
    highlightColor: '#ff82cf',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition,
  tiktok: {
    previewSettings: {
      displayName: 'TikTok',
      scaling: 1.6,
      previewText: [
        'Viral',
        'Scroll-stopping',
        'Trendy',
        'Unleash',
        'the',
        'magic',
        'with',
        'a',
        'look',
        'that',
        'goes',
        'viral',
        'faster',
        'than',
        'a',
        'dance',
        'challenge!'
      ]
    },
    createdAt: new Date(2025, 3, 19),
    cssClass: 'uppercase',
    fontFamily: 'TikTok',
    fontSize: {
      fontSize: 38,
      fontWeight: 400,
      lineHeight: 1
    },
    style: {
      color: 'default',
    },
    colors: [
      '#000000'
    ],
    highlightColor: '#750000',
    effects: {},
    tags: ['simple']
  } as CaptionStyleDefinition
} as const
