// Helper to convert HSB to HEX
export function hsbToHex(h: number, s: number, b: number): string {
  const rgb = hsbToRgb(h, s, b);
  return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}

// Helper to convert HEX to HSB
export function hexToHsb(hex: string): { h: number; s: number; b: number } {
  const rgb = hexToRgb(hex);
  return rgbToHsb(rgb.r, rgb.g, rgb.b);
}

// Convert hex to RGB
export function hexToRgb(hex: string) {
  const sanitizedHex = hex.replace('#', '');
  const bigint = parseInt(sanitizedHex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

// Convert HSB to RGB
export function hsbToRgb(h: number, s: number, b: number) {
  s /= 100;
  b /= 100;
  const k = (n: number) => (n + h / 60) % 6;
  const f = (n: number) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
  return {
    r: Math.round(255 * f(5)),
    g: Math.round(255 * f(3)),
    b: Math.round(255 * f(1)),
  };
}

// Convert RGB to HSB
export function rgbToHsb(r: number, g: number, b: number) {
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;
  const s = max === 0 ? 0 : (d / max) * 100;
  const v = (max / 255) * 100;
  let h = 0;
  if (d !== 0) {
    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) * 60;
        break;
      case g:
        h = ((b - r) / d + 2) * 60;
        break;
      case b:
        h = ((r - g) / d + 4) * 60;
        break;
    }
  }
  return { h, s, b: v };
}

// Convert RGB to HEX
export function rgbToHex(r: number, g: number, b: number) {
  const hex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  return hex.toLowerCase();
}

export function expandHexColor(hex: string) {
  const normalizedHex = hex.replaceAll('#', '');

  if (normalizedHex.length > 6 || normalizedHex.length < 2) {
    throw new Error(`Unparsable hex ${hex}`);
  }

  if (normalizedHex.length === 6) {
    return `#${normalizedHex}`;
  }

  let newHex = '';
  if (normalizedHex.length === 2) {
    // 2-character hex, expand by repeating each character 3 times (e.g., "ff" -> "ffffff")
    newHex = normalizedHex.split('').map((char) => char.repeat(3)).join('');
  } else if (normalizedHex.length === 3) {
    // 3-character hex, expand by repeating each character twice (e.g., "abc" -> "aabbcc")
    newHex = normalizedHex.split('').map((char) => char.repeat(2)).join('');
  } else if (normalizedHex.length === 4) {
    // 4-character hex: Use the first 3 characters directly, then repeat the 4th character 3 times
    // e.g., "abcd" -> "abcdcd"
    newHex = normalizedHex.substring(0, 2).repeat(2) + normalizedHex.substring(2, 4);
  } else if (normalizedHex.length === 5) {
    // 5-character hex: Use the first 3 characters and add the 4th and 5th once more to create a 6-character hex
    // e.g., "abcde" -> "abcdee"
    newHex = normalizedHex + normalizedHex.split('').at(-1);
  }

  return `#${newHex}`.toLowerCase();
}
