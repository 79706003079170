<script lang="ts" setup>
import { type CSSProperties, computed, onMounted } from 'vue'
import createEmojiRegexp from 'emoji-regex'
import type { CaptionStyleDefinition, StoredCaption } from '@/components/Captions/captionTypes'
import type { ClassValue } from 'clsx'
import { useMemoize } from '@vueuse/core'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'

const emojiRegexp = createEmojiRegexp()

const props = withDefaults(defineProps<{
  emojiPosition?: 'top' | 'left' | 'right' | 'bottom'
  captionStyleDefinition: CaptionStyleDefinition
  emojis?: string[]
  class?: ClassValue
  getStyles: (color: string | undefined) => CSSProperties
  caption: StoredCaption
  words: {
    text: string
    color?: string
    editedByUser?: boolean
  }[]
}>(), {
  emojiPosition: 'top',
  emojis: [] as string[],
  isGradientOverlay: false
})

const findEmojisIn = (text: string) => text.match(emojiRegexp)

function separateEmojisFromText(word: { text: string, color?: string, editedByUser?: boolean }) {

  const emojis = word.text ? findEmojisIn(word.text) : null

  // If the word does not contain any emojis, we can just return the word as is
  if (!emojis || emojis.length === 0) {
    return [{ isEmoji: false, text: word.text ?? '', color: word.color, editedByUser: word.editedByUser }]
  }

  const parts = []
  const texts = word.text.split(emojiRegexp)

  for (let i = 0; i < (texts.length + emojis.length); i++) {

    // Since we're effectively iterating over 2 arrays at the same time, `i` must be 
    // halved to get the correct index for the lookup
    const index = Math.floor(0.5 * i)

    // Alternate between emojis and text, because we know that odd numbers are emojis
    if (i % 2 === 0) {
      parts.push({ isEmoji: false, text: texts[index], color: word.color, editedByUser: word.editedByUser })
    } else {
      parts.push({ isEmoji: true, text: emojis[index], color: word.color, editedByUser: word.editedByUser })
    }
  }

  return parts
}


onMounted(() => {
  setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 0);
})

const stripPunctuationEnabled = computed(() => props.captionStyleDefinition.effects.stripPunctuation)
const strip = useStripPunctuation(stripPunctuationEnabled)

const getTextAndEmojis = useMemoize(separateEmojisFromText)

const captionStyles = computed(() => props.getStyles(undefined))
</script>

<template>
  <template v-if="emojiPosition === 'top'">
    <span
      v-for="(emoji, index) of emojis"
      class="text"
      :key="index"
      :class="props.class"
      :style="captionStyles"
    >
      <span class="text-white">{{ emoji }}</span>
    </span>
    <br v-if="emojis.length > 0"  />
  </template>

  <template v-if="emojiPosition === 'left'">
    <span
      v-for="(emoji, index) of emojis"
      class="text"
      :class="props.class"
      :style="{ ...captionStyles, zIndex: emojis.length + words.length - index }"
      :key="index"
    >
      <span class="text-white">{{ emoji }}</span>
    </span>
    <span
      v-if="emojis.length > 0"
      class="text"
      :class="props.class"
      :style="captionStyles"
    >&nbsp;</span>
  </template>

  <template v-for="(word, index) in words" :key="`${word}-${index}`">
    <span
      class="text"
      :class="props.class, { 'text-background': captionStyleDefinition.style.background?.opacity }"
      :style="{ ...getStyles(word.color), zIndex: (emojiPosition === 'right' ? emojis.length : 0) + words.length - index }"
    >
      <template v-for="(part, index) in getTextAndEmojis(word)" :key="`${part.text}-${index}`">
        <span v-if="part.isEmoji" class="text-white">{{ strip(part) }}</span>
        <template v-else>{{ strip(part) }}</template>
      </template>
    </span>
  </template>

  <template v-if="emojiPosition === 'right'">
    <span
      v-if="emojis.length > 0"
      class="text"
      :class="props.class"
      :style="{ ...captionStyles, zIndex: 0 }"
    >&nbsp;</span>
    <span
      v-for="(emoji, index) of emojis"
      class="text"
      :class="props.class"
      :style="{ ...captionStyles, zIndex: emojis.length - index }"
      :key="index"
    >
      <span class="text-white">{{ emoji }}</span>
    </span>
  </template>

  <template v-if="emojiPosition === 'bottom'">
    <br v-if="emojis.length > 0"  />
    <span
      v-for="(emoji, index) of emojis"
      class="text"
      :key="index"
      :class="props.class"
      :style="captionStyles"
    >
      <span class="text-white">{{ emoji }}</span>
    </span>
  </template>
</template>

<style scoped>
  .text {
      font-weight: 400;
      color: var(--color);
      --border-size: 3px;
      text-shadow: var(--text-border, 0 0 0 transparent), var(--text-shadow, 0 0 0 transparent);
      filter: drop-shadow(var(--drop-shadow));
  }
  
  .text-border {
      font-style: normal;
      --text-border: 0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
      0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color);
  }
  
  .text-shadow {
      font-style: normal;
      --text-shadow: 0 0 var(--shadow-width, 10px) var(--shadow-color), 0 0 var(--shadow-width, 10px) var(--shadow-color),
      0 0 var(--shadow-width, 10px) var(--shadow-color);
  }
  
  .text-gradient {
      background-image: var(--gradient);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent !important;
  }

  .text-background {
      display: inline;
      color: var(--second-color);
      font-weight: 700;
      position: relative;
      -webkit-box-decoration-break: clone;
      background-color: var(--background-color);
      border-radius: var(--background-radius);

      padding: 0.5ch;
      margin: -0.4ch;
  }

  .text-background-fade::before {
      background-color: var(--background-color);
  }
  .stroke {
      color: var(--second-color);
  }

  .text-renderer {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
      border: 0;
      filter: none;
      caret-color: rgb(43, 209, 215);
      text-shadow: none;
  }
</style>
