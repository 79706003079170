export const sounds = [
  {
    "id": "0fc23674-3a71-4f2b-9cf6-f97207dd7cbb",
    "url": "/audio-effects/sounds/bleep/censorship-beep-long.mp3",
    "title": "Censorship beep",
    "duration": 6,
    "volume": 0.3,
    "hidden": false
  },
  {
    "id": "7e1b0ec1-9b6c-4e47-ba77-b4f4dc102f74",
    "url": "/audio-effects/sounds/bleep/dolphin-sound.mov",
    "title": "Dolphin click",
    "duration": 0.5,
    "volume": 1,
    "hidden": false
  },
  {
    "id": "880502dc-add8-4a41-bf5b-ac2683dbb6a9",
    "url": "/audio-effects/sounds/bleep/duck-quack.mov",
    "title": "Duck quack",
    "duration": 0.35,
    "volume": 0.3,
    "hidden": false
  },
  {
    "id": "9e42f149-7277-467e-9d20-dca3dbe9e8dd",
    "url": "/audio-effects/sounds/bleep/cash-register-kaching.mov",
    "title": "Cash register kaching",
    "duration": 3,
    "volume": 0.3,
    "hidden": true
  },
  {
    "id": "ea93b7c6-e18d-41ac-8b5a-ca14291251d8",
    "url": "/audio-effects/sounds/bleep/cat-meow.mov",
    "title": "Cat meow",
    "duration": 1,
    "volume": 0.3,
    "hidden": true
  },
  {
    "id": "3d4cbf08-62c2-4771-8e62-c2fe78451bc6",
    "url": "/audio-effects/sounds/bleep/crow.mov",
    "title": "Crow",
    "duration": 11,
    "volume": 0.3,
    "hidden": true
  },
  {
    "id": "9dc2451e-b04c-4345-a355-84e8e08a621f",
    "url": "/audio-effects/sounds/bleep/dog-barking.wav",
    "title": "Dog barking",
    "duration": 2,
    "volume": 0.3,
    "hidden": true
  },
  {
    "id": "dd9b03dd-7ea7-44e3-8878-75e8f092023e",
    "url": "/audio-effects/sounds/bleep/hawk-scream.mov",
    "title": "Hawk scream",
    "duration": 2,
    "volume": 0.3,
    "hidden": true
  },
  {
    "id": "7a82a542-0e09-4d25-a52d-42551e5c9064",
    "url": "/audio-effects/sounds/bleep/rubber-duck-slow-release-squeak.mov",
    "title": "Rubber duck squeak",
    "duration": 1,
    "volume": 0.3,
    "hidden": false
  },
  {
    "id": "040e5369-9af8-405e-95c3-8d5c006a0774",
    "url": "/audio-effects/sounds/bleep/wild-geese.wav",
    "title": "Wild geese",
    "duration": 5,
    "volume": 0.3,
    "hidden": true
  }
];