import type { LayoutWithCrops } from '@/areas/editor/@type/editor-project/Layout';
import type { Element, RiveIcon } from '@/areas/editor/@type/editor-project/Element';
import type { SoundEffect } from '@/areas/editor/@type/editor-project/Effect';
import type { LayoutPreset } from '@/areas/editor/@data/layouts';
import type { TypedSticker } from '@/areas/editor/@type/Project';
import { toRiveConfig } from '@/areas/editor/@data/convert/StickerToRiveMap';
import { useUserInfoStore } from '@/store/user/userInfo';
import { textStickerToCaptionPreset } from '@/areas/editor/@data/convert/textStickerToCaptionPreset';

export type VideoEditorTemplate = LayoutWithCrops
  & { elements: Element[], sounds: SoundEffect[] }
  & { origin: 'saved-template' | 'custom-layout' | 'prefab' }

export function toVideoEditorTemplate(preset: LayoutPreset): VideoEditorTemplate {
  return {
    id: preset.id,
    name: preset.name,
    origin: preset.origin,
    crops: preset.crops,
    sounds: preset.sounds?.map(sound => toRaw(sound)) ?? [],
    presetId: preset.presetId,
    elements: preset.stickers
      .map(stickerToElement)
      .filter(isNotNull),
  }
}

export function stickerToElement(sticker: TypedSticker): Element | null {
  
  // For some reason these are marked as custom for me...
  if (['sprinkle', 'comfy-cat-a', 'comfy-cat-b'].includes(sticker.key)) {
    return socialStickerToRiveElement(sticker as TypedSticker<'social'>)
  }

  try {
    switch (sticker.type) {
      case 'text': return textStickerToTextElement(sticker);
      case 'giphy': return giphyStickerToImageElement(sticker);
      case 'rive': return riveStickerToRiveElement(sticker);
      case 'brand-kit': return brandKitStickerToTextElement(sticker);
      case 'custom': return customStickerToImageElement(sticker);
      case 'social': return socialStickerToRiveElement(sticker);
      case 'twitch-emote': return twitchEmoteStickerToImageElement(sticker);
      default: return null;
    }
  } catch (e) {
    console.error('Error while converting sticker to element; skipping...', e);
    return null;
  }
}

function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

function textStickerToTextElement(sticker: TypedSticker<'text'>): Element<'text'> {
  return {
    id: sticker.id,
    type: 'text',
    text: sticker.textContent,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    preset: textStickerToCaptionPreset(sticker),
    z: sticker.z
  }
}

function giphyStickerToImageElement(sticker: TypedSticker<'giphy'>): Element<'image'> {
  return {
    id: sticker.id,
    type: 'image',
    contentType: 'image/webp',
    url: sticker.imageUrl,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    z: sticker.z
  }
}

function customStickerToImageElement(sticker: TypedSticker<'custom'>): Element<'image'> {
  return {
    id: sticker.id,
    type: 'image',
    subtype: 'custom',
    contentType: 'image/png',
    url: sticker.imageUrl,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    z: sticker.z
  }
}

function brandKitStickerToTextElement(sticker: TypedSticker<'brand-kit'>): Element<'text'> {
  return {
    id: sticker.id,
    type: 'text',
    text: sticker.textContent,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    preset: textStickerToCaptionPreset(sticker),
    z: sticker.z
  }
}

function socialStickerToRiveElement(sticker: TypedSticker<'social'>): Element<'rive'> | null {

  const result = findRivePresetOf(sticker);

  if (!result) {
    return null;
  }
  
  const [artboard, preset] = result as [string, number];
  return {
    id: sticker.id,
    type: 'rive',
    icon: sticker.icon as RiveIcon | undefined,
    artboard: artboard,
    volume: 1.0,
    preset: preset,
    text: sticker.textContent || useUserInfoStore().userName,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    z: sticker.z
  }
}

function findRivePresetOf(sticker: TypedSticker<'social'>) {
  if (sticker.icon === 'rive') {
    return [sticker.key, 0];
  } else {
    const config = toRiveConfig(sticker.key, sticker.color, sticker.icon);
    if (config) {
      return [config.artboard, config.stateIndex];
    } else {
      return null;
    }
  }
}

function riveStickerToRiveElement(sticker: TypedSticker<'rive'>): Element<'rive'> {
  return {
    id: sticker.id,
    type: 'rive',
    icon: sticker.icon as RiveIcon | undefined,
    volume: sticker.volume ?? 1.0,
    preset: undefined,
    artboard: sticker.artboard,
    text: sticker.textContent,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    z: sticker.z
  }
}

function twitchEmoteStickerToImageElement(sticker: TypedSticker<'twitch-emote'>): Element<'image'> {
  return {
    id: sticker.id,
    type: 'image',
    subtype: 'emote',
    contentType: 'image/png',
    url: sticker.imageUrl!,
    area: sticker.area,
    startMs: sticker.startMs,
    endMs: sticker.endMs,
    z: sticker.z
  }
}
