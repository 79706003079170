import { useLocalStorage } from "@vueuse/core"

export const getLastUsedCaptionStyle = () => {
  const lastCaptionStyle = localStorage.getItem('lastCaptionStyle')
  return lastCaptionStyle
}

export const setLastUsedCaptionStyle = (captionStyle: string) => {
  localStorage.setItem('lastCaptionStyle', captionStyle)
}

export const lastUsedCaptionPreset = useLocalStorage('lastCaptionStyle', '')
