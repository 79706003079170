/**
 * This file provides bidirectional mapping between old sticker configurations
 * and Rive sticker configurations.
 */

// Old sticker config structure
export interface OldStickerConfig {
  key: string;
  color: string;
  icon: string;
  component: string; // This is the component name like "GenericRoundedFollow"
}

// Rive sticker configuration
export interface RiveConfig {
  artboard: string;
  stateIndex: number;
}

// Bidirectional mapping between old sticker configs and Rive configs
export type StickerMapper = {
  old: OldStickerConfig;
  rive: RiveConfig;
}[];

// The mappings between sticker types and Rive configurations
export const stickerMappings: StickerMapper = [
  // GenericRoundedFollow mappings
  {
    old: { key: 'twitch-generic-rounded-follow', color: 'dark', icon: 'twitch', component: 'GenericRoundedFollow' },
    rive: { artboard: 'Lowerthird 1', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-rounded-follow', color: 'light', icon: 'twitch', component: 'GenericRoundedFollow' },
    rive: { artboard: 'Lowerthird 1', stateIndex: 1 },
  },

  // GenericTranslucent mappings
  {
    old: { key: 'twitch-generic-translucent', color: 'dark', icon: 'twitch', component: 'GenericTranslucent' },
    rive: { artboard: 'Lowerthird 2', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-translucent', color: 'twitch', icon: 'twitch', component: 'GenericTranslucent' },
    rive: { artboard: 'Lowerthird 2', stateIndex: 1 },
  },
  {
    old: { key: 'youtube-generic-translucent', color: 'youtube', icon: 'youtube', component: 'GenericTranslucent' },
    rive: { artboard: 'Lowerthird 2', stateIndex: 2 },
  },
  {
    old: { key: 'kick-generic-translucent', color: 'kick', icon: 'kick', component: 'GenericTranslucent' },
    rive: { artboard: 'Lowerthird 2', stateIndex: 3 },
  },
  {
    old: { key: 'facebook-generic-translucent', color: 'facebook', icon: 'facebook', component: 'GenericTranslucent' },
    rive: { artboard: 'Lowerthird 2', stateIndex: 4 },
  },

  // GenericSquare mappings
  {
    old: { key: 'twitch-generic-square', color: 'dark', icon: 'twitch', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-square', color: 'color', icon: 'twitch', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 1 },
  },
  {
    old: { key: 'youtube-generic-square', color: 'color', icon: 'youtube', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 2 },
  },
  {
    old: { key: 'youtube-generic-square', color: 'dark', icon: 'youtube', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 3 },
  },
  {
    old: { key: 'youtube-generic-square', color: 'light', icon: 'youtube', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 4 },
  },
  {
    old: { key: 'kick-generic-square', color: 'dark', icon: 'kick', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 5 },
  },
  {
    old: { key: 'kick-generic-square', color: 'color', icon: 'kick', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 6 },
  },
  {
    old: { key: 'kick-generic-square', color: 'light', icon: 'kick', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 7 },
  },
  {
    old: { key: 'twitch-generic-square', color: 'light', icon: 'twitch', component: 'GenericSquare' },
    rive: { artboard: 'Lowerthird 3', stateIndex: 8 },
  },

  // GenericRound mappings
  {
    old: { key: 'youtube-generic-round', color: 'dark', icon: 'youtube', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-round', color: 'dark', icon: 'twitch', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 0 },
  },
  {
    old: { key: 'youtube-generic-round', color: 'light', icon: 'youtube', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-round', color: 'light', icon: 'twitch', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-round', color: 'color', icon: 'twitch', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 2 },
  },
  {
    old: { key: 'youtube-generic-round', color: 'color', icon: 'youtube', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 3 },
  },
  {
    old: { key: 'kick-generic-round', color: 'color', icon: 'kick', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 4 },
  },
  {
    old: { key: 'kick-generic-round', color: 'light', icon: 'kick', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 5 },
  },
  {
    old: { key: 'kick-generic-round', color: 'dark', icon: 'kick', component: 'GenericRound' },
    rive: { artboard: 'Lowerthird 4', stateIndex: 6 },
  },

  // GenericSquareRounded mappings
  {
    old: { key: 'twitch-generic-square-rounded', color: 'dark', icon: 'twitch', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-square-rounded', color: 'color', icon: 'twitch', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-square-rounded', color: 'light', icon: 'twitch', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 2 },
  },
  {
    old: { key: 'youtube-generic-square-rounded', color: 'color', icon: 'youtube', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 3 },
  },
  {
    old: { key: 'youtube-generic-square-rounded', color: 'dark', icon: 'youtube', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 4 },
  },
  {
    old: { key: 'youtube-generic-square-rounded', color: 'light', icon: 'youtube', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 5 },
  },
  {
    old: { key: 'kick-generic-square-rounded', color: 'color', icon: 'kick', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 6 },
  },
  {
    old: { key: 'kick-generic-square-rounded', color: 'dark', icon: 'kick', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 7 },
  },
  {
    old: { key: 'kick-generic-square-rounded', color: 'light', icon: 'kick', component: 'GenericSquareRounded' },
    rive: { artboard: 'Lowerthird 5', stateIndex: 8 },
  },

  // GenericSquareLive mappings
  {
    old: { key: 'twitch-generic-square-live', color: 'color', icon: 'twitch', component: 'GenericSquareLive' },
    rive: { artboard: 'Lowerthird 6', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-square-live', color: 'light', icon: 'twitch', component: 'GenericSquareLive' },
    rive: { artboard: 'Lowerthird 6', stateIndex: 1 },
  },

  // GenericRounded mappings
  {
    old: { key: 'twitch-generic-rounded', color: 'dark', icon: 'twitch', component: 'GenericRounded' },
    rive: { artboard: 'Lowerthird 7', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-rounded', color: 'color', icon: 'twitch', component: 'GenericRounded' },
    rive: { artboard: 'Lowerthird 7', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-rounded', color: 'light', icon: 'twitch', component: 'GenericRounded' },
    rive: { artboard: 'Lowerthird 7', stateIndex: 2 },
  },

  // GenericRainbow mappings
  {
    old: { key: 'twitch-generic-rainbow', color: 'dark', icon: 'twitch', component: 'GenericRainbow' },
    rive: { artboard: 'Lowerthird 8', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-rainbow', color: 'color', icon: 'twitch', component: 'GenericRainbow' },
    rive: { artboard: 'Lowerthird 8', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-rainbow', color: 'light', icon: 'twitch', component: 'GenericRainbow' },
    rive: { artboard: 'Lowerthird 8', stateIndex: 2 },
  },

  // GenericA mappings
  {
    old: { key: 'twitch-generic-a', color: 'blue', icon: 'twitch', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-a', color: 'red', icon: 'twitch', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-a', color: 'green', icon: 'twitch', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 2 },
  },
  {
    old: { key: 'youtube-generic-a', color: 'blue', icon: 'youtube', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 0 },
  },
  {
    old: { key: 'youtube-generic-a', color: 'red', icon: 'youtube', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 1 },
  },
  {
    old: { key: 'youtube-generic-a', color: 'green', icon: 'youtube', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 2 },
  },
  {
    old: { key: 'facebook-generic-a', color: 'blue', icon: 'facebook', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 0 },
  },
  {
    old: { key: 'facebook-generic-a', color: 'red', icon: 'facebook', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 1 },
  },
  {
    old: { key: 'facebook-generic-a', color: 'green', icon: 'facebook', component: 'GenericA' },
    rive: { artboard: 'Lowerthird 9', stateIndex: 2 },
  },

  // GenericB mappings
  {
    old: { key: 'twitch-generic-b', color: 'black', icon: 'twitch', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 0 },
  },
  {
    old: { key: 'youtube-generic-b', color: 'black', icon: 'youtube', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 0 },
  },
  {
    old: { key: 'facebook-generic-b', color: 'black', icon: 'facebook', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 0 },
  },
  {
    old: { key: 'twitch-generic-b', color: 'blue', icon: 'twitch', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 1 },
  },
  {
    old: { key: 'youtube-generic-b', color: 'blue', icon: 'youtube', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 1 },
  },
  {
    old: { key: 'facebook-generic-b', color: 'blue', icon: 'facebook', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 1 },
  },
  {
    old: { key: 'twitch-generic-b', color: 'red', icon: 'twitch', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 2 },
  },
  {
    old: { key: 'youtube-generic-b', color: 'red', icon: 'youtube', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 2 },
  },
  {
    old: { key: 'facebook-generic-b', color: 'red', icon: 'facebook', component: 'GenericB' },
    rive: { artboard: 'Lowerthird 12', stateIndex: 2 },
  },
  
  // GenericC mappings
  {
    old: { key: 'twitch-generic-c', color: 'purple', icon: 'twitch', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 0 },
  },
  {
    old: { key: 'facebook-generic-c', color: 'red', icon: 'facebook', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 1 },
  },
  {
    old: { key: 'youtube-generic-c', color: 'blue', icon: 'youtube', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 2 },
  },
  {
    old: { key: 'twitch-generic-c', color: 'orange', icon: 'twitch', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 3 },
  },
  {
    old: { key: 'facebook-generic-c', color: 'orange', icon: 'facebook', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 3 },
  },
  {
    old: { key: 'youtube-generic-c', color: 'orange', icon: 'youtube', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 3 },
  },
  {
    old: { key: 'twitch-generic-c', color: 'pink', icon: 'twitch', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 4 },
  },
  {
    old: { key: 'facebook-generic-c', color: 'pink', icon: 'facebook', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 4 },
  },
  {
    old: { key: 'youtube-generic-c', color: 'pink', icon: 'youtube', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 4 },
  },
  {
    old: { key: 'twitch-generic-c', color: 'green', icon: 'twitch', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 5 },
  },
  {
    old: { key: 'facebook-generic-c', color: 'green', icon: 'facebook', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 5 },
  },
  {
    old: { key: 'youtube-generic-c', color: 'green', icon: 'youtube', component: 'GenericC' },
    rive: { artboard: 'Lowerthird 10', stateIndex: 5 },
  },

  // GenericD mappings
  {
    old: { key: 'twitch-generic-d', color: 'purple', icon: 'twitch', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 0 },
  },
  {
    old: { key: 'youtube-generic-d', color: 'red', icon: 'youtube', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 1 },
  },
  {
    old: { key: 'facebook-generic-d', color: 'blue', icon: 'facebook', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 2 },
  },
  {
    old: { key: 'twitch-generic-d', color: 'aqua', icon: 'twitch', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 3 },
  },
  {
    old: { key: 'youtube-generic-d', color: 'aqua', icon: 'youtube', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 3 },
  },
  {
    old: { key: 'facebook-generic-d', color: 'aqua', icon: 'facebook', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 3 },
  },
  {
    old: { key: 'twitch-generic-d', color: 'black', icon: 'twitch', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 4 },
  },
  {
    old: { key: 'youtube-generic-d', color: 'black', icon: 'youtube', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 4 },
  },
  {
    old: { key: 'facebook-generic-d', color: 'black', icon: 'facebook', component: 'GenericD' },
    rive: { artboard: 'Lowerthird 11', stateIndex: 4 },
  },

  // Sprinkle mappings
  {
    old: { key: 'sprinkle', color: '', icon: '', component: 'Sprinkle' },
    rive: { artboard: 'Lowerthird 13', stateIndex: 0 },
  },

  // ComfyCatB mappings
  {
    old: { key: 'comfy-cat-b', color: '', icon: '', component: 'ComfyCatB' },
    rive: { artboard: 'Lowerthird 14', stateIndex: 0 },
  },

  // ComfyCatA mappings
  {
    old: { key: 'comfy-cat-a', color: '', icon: '', component: 'ComfyCatA' },
    rive: { artboard: 'Lowerthird 15', stateIndex: 0 },
  }
];

/**
 * Converts an old sticker configuration to a Rive configuration
 */
export function toRiveConfig(key: string, color: string, icon: string): RiveConfig | null {

  // Find an exact match
  const exactMatch = stickerMappings.find(mapping => {
    return mapping.old.key === key
      && mapping.old.color === color
      && mapping.old.icon === icon;
  });

  // Return the Rive config if we found an exact match
  if (exactMatch) {
    return exactMatch.rive;
  }

  // Special case handling for component matching (similar to StickerToRiveConverter behavior)
  const componentMatch = stickerMappings.find(mapping => mapping.old.key === key);
  const component = componentMatch?.old.component;
  
  if (component) {
    // If we know the component but not the exact configuration, try to find a match
    // with the same component, color and icon
    const colorIconMatch = stickerMappings.find(mapping => {
      return mapping.old.component === component
        && mapping.old.color === color
        && mapping.old.icon === icon;
    });

    if (colorIconMatch) {
      return colorIconMatch.rive;
    }

    // Otherwise, try to find a match with just the component and color
    const colorMatch = stickerMappings.find(mapping => {
      return mapping.old.component === component
        && mapping.old.color === color;
    });

    if (colorMatch) {
      return colorMatch.rive;
    }

    // If nothing else, return the first match with this component
    const anyComponentMatch = stickerMappings.find(mapping => {
      return mapping.old.component === component;
    });

    if (anyComponentMatch) {
      return anyComponentMatch.rive;
    } else {
      return componentMatch.rive;
    }
  }

  return null;
}

/**
 * Converts a Rive configuration to the closest matching old sticker configuration
 *
 * @param riveConfig Rive configuration object
 * @param preferredIcon Optional preferred icon to use when multiple matches exist (default: "twitch")
 * @returns Old sticker configuration if a match is found, null otherwise
 */
export function toOldStickerConfig(riveConfig: RiveConfig, preferredIcon: string = 'twitch'): OldStickerConfig | null {
  // Find an exact match
  const exactMatches = stickerMappings.filter(mapping => {
    return mapping.rive.artboard === riveConfig.artboard
      && mapping.rive.stateIndex === riveConfig.stateIndex;
  });

  if (exactMatches.length > 0) {
    // If we have multiple matches, prefer the one with the preferred icon
    const preferredMatch = exactMatches.find(mapping => mapping.old.icon === preferredIcon);
    return preferredMatch ? preferredMatch.old : exactMatches[0].old;
  }

  // If no exact match, find stickers with the same artboard
  const artboardMatches = stickerMappings.filter(mapping => {
    return mapping.rive.artboard === riveConfig.artboard;
  });

  if (artboardMatches.length > 0) {
    // Find the closest state index

    let closest = artboardMatches[0];
    for (const current of artboardMatches) {

      const closestDiff = Math.abs(closest.rive.stateIndex - riveConfig.stateIndex);
      const currentDiff = Math.abs(current.rive.stateIndex - riveConfig.stateIndex);

      // If diffs are equal, prefer the one with the preferred icon
      if (currentDiff === closestDiff) {
        if (current.old.icon === preferredIcon) {
          closest = current;
        }
      } else if (currentDiff < closestDiff) {
        closest = current;
      }
    }

    return closest.old;
  }

  return null;
}
