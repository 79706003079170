import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useSLVideoController } from '@/modules/SLVideoplayer/hooks/useSLVideoController'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { gsap } from 'gsap'

export const _globalTimeline = gsap.timeline({
  repeat: -1,
  repeatDelay: 0,
  paused: true,
})

export const useEditorVideoStore = defineStore('editorVideo', () => {
  // we won't expose this element directly
  const videoElement = ref<HTMLVideoElement>()
  const src = ref('')

  const editorFeedData = useEditorFeedDataStore()

  const segments = computed(() => {
    return editorFeedData.renderFragments?.map((segment) => ({
      startMs: segment.start,
      endMs: segment.end,
    })) ?? []
  })

  const videoSize = ref<{ width: number, height: number } | null>(null)

  const videoController = useSLVideoController(videoElement, segments)

  const preservedPaused = ref(false)

  const preservedMuted = ref(true)
  watch(videoController.muted, (muted) => {
    preservedMuted.value = muted
  })

  const scrubbing = ref(false)

  const isLoaded = computed(() => {
    return videoElement.value !== undefined
  })

  function getExactTime() {
    return (videoElement.value?.currentTime ?? 0) * 1000
  }

  function loadVideo(element: HTMLVideoElement, _src: string) {
    videoElement.value = element
    element.oncanplay = () => {
      videoSize.value = {
        width: element.videoWidth,
        height: element.videoHeight,
      }
      setMainStore(element)
    }
    videoController.muted.value = preservedMuted.value
    src.value = _src
  }

  function setMainStore(element: HTMLVideoElement) {
    const editorMainStore = useEditorMainStore()
    if (editorMainStore.videoHeight === 0 || editorMainStore.trimmedEndTime === 0) {
      // editorMainStore.videoDuration = Math.round(element.duration * 1000)
      editorMainStore.videoHeight = element.videoHeight
      editorMainStore.videoWidth = element.videoWidth
    }
  }

  function unmountElement() {
    // When the video element is removed from the DOM, the src attribute should be set to an empty string to allow garbage collecting.
    // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
    if (videoElement.value) {
      videoElement.value.pause()
      videoElement.value.src = ''
      videoElement.value.load()
      videoElement.value = undefined
      src.value = ''
    }
  }

  return {
    ...videoController,
    segments,
    loadVideo,
    getExactTime,
    videoElement,
    videoSize,
    scrubbing,
    isLoaded,
    unmountElement,
    preservedPaused
    // timeline: timeline,
  }
})
