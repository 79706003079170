import type { TypedSticker } from '@/areas/editor/@type/Project';
import type { CaptionPreset } from '@/areas/editor/@type/Captions';
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset';
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager';
import type { FontLabel } from '@/data/fonts';
import { fontsData } from '@/data/fonts';
import { captionPresets, type CaptionPresetKey } from '@/data/captionPresets';
import textLibrary from '@/components/Stickers/textLibrary';

const oldTextStickers = [ "padded",  "outline",  "glow",  "transparent",  "regular" ];
export function textStickerToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {
  if (oldTextStickers.includes(sticker.variant)) {
    return oldTextStickerToCaptionPreset(sticker);
  } else {
    if (captionPresets[sticker.variant as CaptionPresetKey]) {
      return captionPresets[sticker.variant as CaptionPresetKey];
    }

    const color = 'color' in sticker ? sticker.color : sticker.primaryColor;
    const stickerData = textLibrary.find((e) => {
      return e.key === sticker.key || e.title === sticker.key || e.variant === sticker.variant;
    });

    return captionStyleToCaptionPreset((stickerData?.variant ?? sticker.variant) as CaptionStyle, color);
  }
}

function oldTextStickerToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {
  switch (sticker.variant) {
    case 'padded': return paddedToCaptionPreset(sticker);
    case 'outline': return outlineToCaptionPreset(sticker);
    case 'glow': return glowToCaptionPreset(sticker);
    case 'transparent': return transparentToCaptionPreset(sticker);
    case 'regular': return regularToCaptionPreset(sticker);
    default: return regularToCaptionPreset(sticker);
  }
}

function fontOf(sticker: TypedSticker<'text' | 'brand-kit'>): FontLabel {

  if ('fontFamily' in sticker && sticker.fontFamily) {
    return sticker.fontFamily
  }

  switch (sticker.variant) {
    case 'outline':
      return 'Bangers'
    case 'outline-2':
      return 'Luckiest Guy'
    case 'fat-montserrat':
      return 'Montserrat Black'
    case 'padded':
    case 'glow':
    case 'transparent':
    case 'regular':
      return 'Metropolis'

    default:
      return 'metropolis'
  }
}

function colorsOf(sticker: TypedSticker<'text' | 'brand-kit'>): { primary: string, secondary: string } {

  if ('primaryColor' in sticker && 'secondaryColor' in sticker) {
    return {
      primary: sticker.primaryColor,
      secondary: sticker.secondaryColor
    }
  }

  if ('color' in sticker) {
    if (sticker.variant === 'padded') {
      switch (sticker.color) {
        case 'white': return { primary: 'black', secondary: 'white' };
        case 'black': return { primary: 'white', secondary: 'black' };
        case 'red': return { primary: 'white', secondary: '#ea4040' };
        case 'orange': return { primary: 'white', secondary: '#ff923d' };
        case 'yellow': return { primary: 'white', secondary: '#f2ce46' };
        case 'green': return { primary: 'white', secondary: '#77c25d' };
        case 'lightblue': return { primary: 'white', secondary: '#3496ef' };
        case 'pink': return { primary: 'white', secondary: '#f8d7e8' };
      }
    } else if (sticker.variant === 'outline') {
      switch (sticker.color) {
        case 'white': return { primary: 'black', secondary: 'white' };
        case 'black': return { primary: 'white', secondary: 'black' };
        case 'red': return { primary: '#ea4040', secondary: 'white' };
        case 'orange': return { primary: '#ff923d', secondary: '#7a3500' };
        case 'yellow': return { primary: '#f2ce46', secondary: '#665106' };
        case 'green': return { primary: '#77c25d', secondary: 'white' };
        case 'lightblue': return { primary: '#3496ef', secondary: 'white' };
        case 'pink': return { primary: '#f8d7e8', secondary: '#c66f92' };
      }
    } else if (sticker.variant === 'glow') {
      switch (sticker.color) {
        case 'blue': return { primary: 'white', secondary: '#0808f1' };
        case 'red': return { primary: 'white', secondary: '#ea4040' };
        case 'yellow': return { primary: 'white', secondary: '#ffff00' };
        case 'pink': return { primary: 'white', secondary: '#f8d7e8' };
      }
    } else if (sticker.variant === 'transparent') {
      switch (sticker.color) {
        case 'white': return { primary: 'white', secondary: '#000000' };
        case 'black': return { primary: 'black', secondary: '#ffffff' };
        case 'red': return { primary: '#ea4040', secondary: '#000000' };
        case 'orange': return { primary: '#ff923d', secondary: '#000000' };
        case 'yellow': return { primary: '#f2ce46', secondary: '#000000' };
        case 'pink': return { primary: '#f8d7e8', secondary: '#000000' };
        case 'green': return { primary: '#77c25d', secondary: '#000000' };
        case 'lightblue': return { primary: '#3496ef', secondary: '#000000' };
      }
    } else {
      switch (sticker.color) {
        case 'white':
          return { primary: 'white', secondary: 'black' };
        case 'black':
          return { primary: 'black', secondary: 'white' };
        case 'red':
          return { primary: 'red', secondary: 'white' };
        case 'orange':
          return { primary: '#ff923d', secondary: '#000000' };
        case 'yellow':
          return { primary: '#f2ce46', secondary: '#000000' };
        case 'pink':
          return { primary: '#f8d7e8', secondary: '#000000' };
        case 'green':
          return { primary: '#77c25d', secondary: 'white' };
        case 'lightblue':
          return { primary: '#3496ef', secondary: 'white' };
      }
    }
  }

  return { primary: 'white', secondary: 'black' };
}

function paddedToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {

  const fontFamily = fontOf(sticker);
  const fontData = fontsData.fonts.find((e) => e.label.toLowerCase() === fontFamily.toLowerCase())
  const colors = colorsOf(sticker);

  return {
    key: 'padded',
    font: {
      editable: true,
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeight: fontFamily.toLowerCase() === 'metropolis' ? 600 : 400,
      leading: (fontData?.lineHeightFactor ?? 1) * 1.5,
      kerning: 0,
      textTransform: 'none',
      color: colors.primary,
      highlightColor: colors.primary,
    },
    stroke: {
      editable: true,
      width: 0,
      color: colors.secondary,
      highlightColor: colors.secondary,
    },
    glow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      radius: 0,
    },
    background: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      opacity: 1,
      radius: 16,
    },
    shadow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0,
    },
    tags: [],
    variants: [],
    createdAt: new Date(1970, 0, 1),
  }
}

function outlineToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {
  
    const fontFamily = fontOf(sticker);
    const fontData = fontsData.fonts.find((e) => e.label.toLowerCase() === fontFamily.toLowerCase())
    const colors = colorsOf(sticker);
  
    return {
      key: 'outline',
      font: {
        editable: true,
        fontFamily: fontFamily,
        fontSize: 16,
        fontWeight: fontFamily.toLowerCase() === 'metropolis' ? 600 : 400,
        leading: (fontData?.lineHeightFactor ?? 1) * 1.2,
        kerning: 0,
        textTransform: 'none',
        color: colors.primary,
        highlightColor: colors.primary,
      },
      stroke: {
        editable: true,
        width: 16,
        color: colors.secondary,
        highlightColor: colors.secondary,
      },
      glow: {
        editable: true,
        color: colors.secondary,
        highlightColor: colors.secondary,
        radius: 0,
      },
      background: {
        editable: true,
        color: colors.secondary,
        highlightColor: colors.secondary,
        opacity: 0,
        radius: 16,
      },
      shadow: {
        editable: true,
        color: colors.secondary,
        highlightColor: colors.secondary,
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        opacity: 0,
      },
      tags: [],
      variants: [],
      createdAt: new Date(1970, 0, 1),
    }
}

function glowToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {

  const fontFamily = fontOf(sticker);
  const fontData = fontsData.fonts.find((e) => e.label.toLowerCase() === fontFamily.toLowerCase())
  const colors = colorsOf(sticker);

  return {
    key: 'glow',
    font: {
      editable: true,
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeight: fontFamily.toLowerCase() === 'metropolis' ? 600 : 400,
      leading: (fontData?.lineHeightFactor ?? 1) * 1.5,
      kerning: 0,
      textTransform: 'none',
      color: colors.primary,
      highlightColor: colors.primary,
    },
    stroke: {
      editable: true,
      width: 0,
      color: colors.secondary,
      highlightColor: colors.secondary,
    },
    glow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      radius: 50,
    },
    background: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      opacity: 0,
      radius: 16,
    },
    shadow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0,
    },
    tags: [],
    variants: [],
    createdAt: new Date(1970, 0, 1),
  }
}

function transparentToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {

  const fontFamily = fontOf(sticker);
  const fontData = fontsData.fonts.find((e) => e.label.toLowerCase() === fontFamily.toLowerCase())
  const colors = colorsOf(sticker);

  return {
    key: 'transparent',
    font: {
      editable: true,
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeight: fontFamily.toLowerCase() === 'metropolis' ? 600 : 400,
      leading: (fontData?.lineHeightFactor ?? 1) * 2.1,
      kerning: 0,
      textTransform: 'none',
      color: colors.primary,
      highlightColor: colors.primary,
    },
    stroke: {
      editable: true,
      width: 0,
      color: colors.secondary,
      highlightColor: colors.secondary,
    },
    glow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      radius: 0,
    },
    background: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      opacity: 0.5,
      radius: 16,
    },
    shadow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0,
    },
    tags: [],
    variants: [],
    createdAt: new Date(1970, 0, 1),
  }
}

function regularToCaptionPreset(sticker: TypedSticker<'text' | 'brand-kit'>): CaptionPreset {

  const fontFamily = fontOf(sticker);
  const fontData = fontsData.fonts.find((e) => e.label.toLowerCase() === fontFamily.toLowerCase())
  const colors = colorsOf(sticker);

  return {
    key: 'regular',
    font: {
      editable: true,
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeight: fontFamily.toLowerCase() === 'metropolis' ? 600 : 400,
      leading: (fontData?.lineHeightFactor ?? 1) * 1.5,
      kerning: 0,
      textTransform: 'none',
      color: colors.primary,
      highlightColor: colors.primary,
    },
    stroke: {
      editable: true,
      width: 0,
      color: colors.secondary,
      highlightColor: colors.secondary,
    },
    glow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      radius: 0,
    },
    background: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      opacity: 0,
      radius: 16,
    },
    shadow: {
      editable: true,
      color: colors.secondary,
      highlightColor: colors.secondary,
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0,
    },
    tags: [],
    variants: [],
    createdAt: new Date(1970, 0, 1), 
  };
}
