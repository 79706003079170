import { defineStore, acceptHMRUpdate } from 'pinia'
import type { LayoutKey } from '@/data/layoutData'
import type { ScaleSet } from '@/modules/SLVideoplayer/types'
import type { StickerData } from '@/store/editor/editorStickers'
import type { SoundEffect } from '@/areas/editor/@type/Project'

interface UserTemplatesState {
  savedTemplates: Template[]
  isLoading: boolean
}

export const useUserTemplatesStore = defineStore('userTemplates', {
  state: (): UserTemplatesState => {
    return {
      savedTemplates: [] as Template[],
      isLoading: true
    }
  },
  getters: {
    getTemplateById: (state) => (id: string) => {
      return state.savedTemplates.find((t) => t.id === id)
    },
  },
})

interface BaseTemplate {
  id: string;
  displayName: string;
  templateName: string;
  ratio: string;
  createdTime: string;
  croppers: {
    cropperKey: string
    data: ScaleSet
  }[];
  feeds: {
    feedKey: string
    data: ScaleSet
  }[];
  stickers: StickerData[];
  sounds?: SoundEffect[];
}

export interface LayoutTemplate extends BaseTemplate {
  layoutName: LayoutKey;
}

export interface CustomTemplate extends BaseTemplate {
  layoutName: 'custom'
  layoutId: string
}

type TemplateUnion = LayoutTemplate | CustomTemplate;

export type Template<TLayoutName extends TemplateUnion['layoutName'] = TemplateUnion['layoutName']> = Extract<TemplateUnion, { layoutName: TLayoutName }>

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useUserTemplatesStore, import.meta.hot))
}
