import { getUser, supabase } from '@/authentication/supabase'
import type { CustomCaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import { retryAsync } from '@/helpers/retry'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { DeepReadonly } from 'vue'

const selectQuery = `
  id: Id,
  name: Name,
  preset: Preset
`

const CUSTOM_CAPTION_PRESETS_KEY = ['customCaptionPresets']

export async function fetchCustomCaptionPresets() {
  const {
    data: { user },
  } = await getUser()

  if (!user) {
    return null
  }

  const { data, error } = await retryAsync(async () => {
    return supabase
      .from('CustomCaptionPresets')
      .select(selectQuery)
      .eq('UserId', user.id)
      .returns<CustomCaptionPreset[]>()
  })

  if (error) {
    throw new Error(error.message)
  }

  return data ?? null
}

export async function storeCustomCaptionPreset(customCaptionPreset: DeepReadonly<CustomCaptionPreset>) {
  const {
    data: { user },
  } = await getUser()

  if (!user) {
    return null
  }

  // Cast `Preset` to `any` to satisfy the JSON requirement,
  const { data, error } = await supabase
    .from('CustomCaptionPresets')
    .upsert({
      Id: customCaptionPreset.id,
      UserId: user.id,
      Name: customCaptionPreset.name,
      Preset: customCaptionPreset.preset as unknown as any,
      UpdatedAt: new Date().toISOString(),
    })
    .select(selectQuery)
    .returns<CustomCaptionPreset[]>()

  if (error) {
    throw new Error(error.message)
  }

  return data ?? null
}

export async function deleteCustomCaptionPreset(customCaptionPresetId: string) {
  const {
    data: { user },
  } = await getUser()

  if (!user) {
    return false
  }

  const { error } = await supabase
    .from('CustomCaptionPresets')
    .delete()
    .eq('Id', customCaptionPresetId)
    .eq('UserId', user.id)

  if (error) {
    throw new Error(error.message)
  }

  return true
}

export function useCustomCaptionPresets() {
  return useQuery<CustomCaptionPreset[] | null>({
    queryKey: CUSTOM_CAPTION_PRESETS_KEY,
    queryFn: fetchCustomCaptionPresets,
    refetchOnMount: false,
  })
}

export function useStoreCustomCaptionPreset() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: storeCustomCaptionPreset,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CUSTOM_CAPTION_PRESETS_KEY })
    },
  })
}

export function useDeleteCustomCaptionPreset() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteCustomCaptionPreset,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CUSTOM_CAPTION_PRESETS_KEY })
    },
  })
}
