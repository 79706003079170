import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'
import { useMemoize } from '@vueuse/core'
import tinyColor from 'tinycolor2'
import { computed, type CSSProperties, type Ref } from 'vue'
import { hexToRgb } from '../colors/helpers'
import type { CaptionPreset } from './v3/CaptionPreset'

export function captionPresetToCaptionStyleDefinition(preset: Ref<CaptionPreset>, style: Ref<CaptionStyleDefinition>) {
  return computed(() => {
    const fontColor = typeof preset.value.font.color === 'string' ? preset.value.font.color : preset.value.font.color.colorStops[0].color
    return {
      ...style.value,
      style: {
        ...style.value.style,
        border: {
          ...style.value.style.border,
          color: preset.value.stroke.color,
          width: preset.value.stroke.width / 4,
        },
        color: preset.value.background.opacity ? preset.value.background.color : fontColor,
        dropShadow: {
          ...style.value.style.dropShadow,
          blur: preset.value.shadow.blur,
          color: preset.value.shadow.color,
          opacity: preset.value.shadow.opacity,
          offset: {
            x: preset.value.shadow.offsetX,
            y: preset.value.shadow.offsetY,
          }
        },
        background: {
          color: preset.value.background.color,
          opacity: preset.value.background.opacity,
          radius: preset.value.background.radius,
        },
      },
      fontSize: {
        ...style.value.fontSize,
        // fontSize: preset.value.font.fontSize,
        // fontWeight: preset.value.font.fontWeight,
      },
      fontFamily: preset.value.font.fontFamily,
      highlightColor: typeof preset.value.font.highlightColor === 'string' ? preset.value.font.highlightColor : preset.value.font.highlightColor.colorStops[0].color,
      createdAt: preset.value.createdAt,
    } as CaptionStyleDefinition
  })
}

export const useCaptionCss = (style: Ref<CaptionStyleDefinition>) => {

  const classes = computed(() => {
    return [
      style.value.cssClass,
      style.value.style.border && 'text-border',
      style.value.style.shadow && 'text-shadow',
      style.value.style.dropShadow && 'drop-shadow',
    ].filter(Boolean)
  })

  const isKappa = computed(() => style.value.cssClass.split(' ').some((item) => item === 'kappa'))

  const getColor = (color: 'default' | 'darker' | 'lighter' | string, baseColor: string) => findColorMemoized(color, baseColor)
  const getStyles = (color: string) => findStylesMemoized(style.value, color)
  const getGradient = (color: string) => findGradientMemoized(style.value, color)
  const getBase64 = (color: string) => findBase64Memoized(color)

  return {
    classes,
    isKappa,
    getColor,
    getStyles,
    getGradient,
    getBase64
  }
}

const canvas = document.createElement('canvas')
canvas.width = 1
canvas.height = 1

// Appears to result in slightly crisper rendering when combined with text gradients than `color: word.color`
const findBase64Memoized = useMemoize((color: string) => {
  const ctx = canvas.getContext('2d')
  if (!ctx) return ''
  ctx.fillStyle = color
  ctx.fillRect(0, 0, 1, 1)
  return `url(${canvas.toDataURL()})`
})


const findGradientMemoized = useMemoize((style: CaptionStyleDefinition, color: string | undefined) => {
  return style.gradients && color ? style.gradients[color] : undefined
})

const findStylesMemoized = useMemoize((style: CaptionStyleDefinition, color: string): CSSProperties => {

  const styles: Record<string, string> = {
    '--color': findColorMemoized(style.style.color, color),
    '--second-color': color === '#FFFFFF' ? '#000000' : '#FFFFFF',
  }

  if (style.style.background?.opacity) {
    const rgb = hexToRgb(style.style.background?.color);
    styles['--background-color'] = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${style.style.background.opacity})`
    styles['--background-radius'] = style.style.background?.radius + "px"
  }

  if (style.style.border?.width) {
    styles['--border-size'] = `${style.style.border.width}px`
    styles['--border-color'] = style.borderColors
      ? style.borderColors[color]
      : findColorMemoized(style.style.border.color, color)
  }

  if (style.style.shadow?.width) {
    styles['--shadow-color'] = findColorMemoized(style.style.shadow.color, color)
    if (style.style.shadow.width) {
      styles['--shadow-width'] = `${style.style.shadow.width}px`
    }
  }

  if (style.style.dropShadow?.opacity) {
    const { offset, blur, color: shadowColor, opacity } = style.style.dropShadow
    styles['--drop-shadow'] = [
      `${offset?.x ?? 0}px`,
      `${offset?.y ?? 0}px`,
      `${blur ?? 0}px`,
      `${tinyColor(findColorMemoized(shadowColor, color))
        .setAlpha(opacity ?? 1)
        .toRgbString()}`,
    ].join(' ')
  }

  if (style.fontSize.fontWeight) {
    styles['font-weight'] = style.fontSize.fontWeight.toString()
  }

  if (findGradientMemoized(style, color)) {
    styles['--color'] = '#000000'
  }

  return { ...style.styleOverrides, ...styles }
})

export const findColorMemoized = useMemoize((color: 'default' | 'darker' | 'lighter' | string, baseColor: string): string => {

  if (color.startsWith('-')) {
    const value = Number(color.replace('-', ''))
    return tinyColor(baseColor).darken(value).toString()
  }

  if (color.startsWith('+')) {
    const value = Number(color.replace('+', ''))
    return tinyColor(baseColor).lighten(value).toString()
  }

  if (color === 'complement') return tinyColor(baseColor).complement().toString()
  if (color === 'default') return baseColor
  if (color === 'darker') return tinyColor(baseColor).darken(30).toString()
  if (color === 'lighter') return tinyColor(baseColor).lighten(70).toString()

  return color
})
