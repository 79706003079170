import { stickerToElement } from '@/areas/editor/@data/convert/toVideoEditorTemplate';
import type { Captions } from '@/areas/editor/@type/Captions';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import type { Crop } from '@/areas/editor/@type/editor-project/Crop';
import type { CropSource } from '@/areas/editor/@type/editor-project/CropSource';
import type { Layout } from '@/areas/editor/@type/editor-project/Layout';
import type { Segment } from '@/areas/editor/@type/editor-project/Segment';
import type { Source } from '@/areas/editor/@type/editor-project/Source';
import type { Profanity } from '@/areas/editor/@type/Profanity';
import type { Project } from '@/areas/editor/@type/Project';
import { metadataService } from '@/services/metadataService';
import { defaultCaptionOptions } from '@/store/editor/editorCaptions';
import { v4 as uuid } from 'uuid';

export const versions = [
  'save-projects-in-supabase',
  'white-label'
] as const;

export const version = versions[versions.length - 1];
export type Version = typeof version;

export async function upgradeProjectToEditorProject(project: EditorProject | Project): Promise<EditorProject> {
  if ('mp4Url' in project) {

    const id = project.id;
    const title = project.title;
    const language = project.language;

    const volume: number = 1;
    const { duration } = await metadataService.fetchVideoUrlMetadata(project.mp4Url!);
    const durationMs: number = duration * 1000;

    const sources: Source[] = [{ id: uuid(), url: project.mp4Url!, contentType: 'video/mp4' }];
    const segments: Segment[] = project.segments.map<Segment>(s => ({ ...s, sourceId: sources[0].id, volume: 1.0, loop: false }));
    const layouts: Layout[] = project.layouts.map<Layout>(l => ({ ...l }));

    const crops: Crop[] = [];
    const cropSources: CropSource[] = [];
    for (const crop of project.crops) {
      crops.push({ id: crop.id, z: crop.z, feedData: crop.feedData, input: crop.input, layoutId: crop.layoutId });
      cropSources.push({ id: uuid(), cropId: crop.id, x: crop.x, y: crop.y, width: crop.width, height: crop.height, startMs: null, endMs: null });
    }

    const effects = project.effects;
    const elements = project.stickers.map<Element>(stickerToElement);

    const preset = project.captions.baseCaptionPreset;
    if (preset && !preset.options) {
      preset.options = project.captions.baseOptions ?? defaultCaptionOptions;
    }

    const captions: Captions = {
      entities: project.captions.entities,
      hasGeneratedCaptions: project.captions.hasGeneratedCaptions,
      captionsArea: project.captions.captionsArea,
      baseCaptionPreset: preset,
      customCaptionPresetId: project.captions.customCaptionPresetId ?? project.captions.currentUserCaption?.id,
    };

    const profanity: Profanity = {
      enabled: project.profanity?.enabled ?? project.captions.bleepCurseWords ?? false,
      generated: project.profanity?.generated ?? project.captions.hasGeneratedProfanity ?? false,
      selectedSoundEffect: project.profanity?.selectedSoundEffect ?? project.captions.bleepCurseWordSoundEffect,
    }

    return {
      id: id,
      title: title,
      language: language,
      durationMs: durationMs,
      volume: volume,
      sources: sources,
      segments: segments,
      layouts: layouts,
      effects: effects,
      crops: crops,
      cropSources: cropSources,
      elements: elements,
      captions: captions,
      profanity: profanity,
    }
  } else {
    return project as EditorProject;
  }
}
