// Note: some properties are pre-calculated by the FontLineHeightCalculator

export const fontsData = {
  fonts: [
    {
      url: "url('/fonts/Aboreto/Aboreto-Regular.ttf')",
      label: 'Aboreto',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Alfa_Slab_One/AlfaSlabOne-Regular.ttf')",
      label: 'Alfa Slab One',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Anton/Anton-Regular.ttf')",
      label: 'Anton',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Bangers/Bangers-Regular.ttf')",
      label: 'Bangers',
      lineHeightFactor: 1.0625,
    },
    {
      url: "url('/fonts/Barrio/Barrio-Regular.ttf')",
      label: 'Barrio',
      lineHeightFactor: 1.125,
    },
    {
      url: "url('/fonts/Bebas_Neue/BebasNeue-Regular.ttf')",
      label: 'Bebas Neue',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Black_Han_Sans/BlackHanSans-Regular.ttf')",
      label: 'Black Han Sans',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Brain_Melt/Brain-Melt.ttf')",
      label: 'Brain Melt',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Bungee/Bungee-Regular.ttf')",
      label: 'Bungee',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Bungee_Inline/BungeeInline-Regular.ttf')",
      label: 'Bungee Inline',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Bungee_Shade/BungeeShade-Regular.ttf')",
      label: 'Bungee Shade',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Cabin_Sketch/CabinSketch-Regular.ttf')",
      label: 'Cabin Sketch',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Chango/Chango-Regular.ttf')",
      label: 'Chango',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Cinzel/Cinzel-Regular.ttf')",
      label: 'Cinzel',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Coming_Soon/ComingSoon-Regular.ttf')",
      label: 'Coming soon',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf')",
      label: 'Cormorant Garamond',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Cormorant_SC/CormorantSC-Regular.ttf')",
      label: 'Cormorant SC',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Creepster/Creepster-Regular.ttf')",
      label: 'Creepster',
      lineHeightFactor: 1.125,
    },
    {
      url: "url('/fonts/Dancing_Script/DancingScript-Regular.ttf')",
      label: 'Dancing Script',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Eater/Eater-Regular.ttf')",
      label: 'Eater',
      lineHeightFactor: 1.875,
    },
    {
      url: "url('/fonts/Faster_One/FasterOne-Regular.ttf')",
      label: 'Faster One',
      lineHeightFactor: 1.0625,
    },
    {
      url: "url('/fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf')",
      label: 'Fira Sans Condensed',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Fira_Sans_Condensed/FiraSansCondensed-ExtraBold.ttf')",
      label: 'Fira Sans Condensed Extra Bold',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Fira_Sans_Condensed/FiraSansCondensed-BlackItalic.ttf')",
      label: 'Fira Sans Condensed Black Italic',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Fontdiner_Swanky/FontdinerSwanky-Regular.ttf')",
      label: 'Fontdiner Swanky',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Fragment_Mono/FragmentMono-Regular.ttf')",
      label: 'Fragment Mono',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Fredoka_One/FredokaOne-Regular.ttf')",
      label: 'Fredoka One',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Frijole/Frijole-Regular.ttf')",
      label: 'Frijole',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Gabarito/Gabarito-ExtraBold.ttf')",
      label: 'Gabarito Extra Bold',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Gochi_Hand/GochiHand-Regular.ttf')",
      label: 'Gochi Hand',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Great_Vibes/GreatVibes-Regular.ttf')",
      label: 'Great Vibes',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Hammersmith_One/HammersmithOne-Regular.ttf')",
      label: 'Hammersmith One',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Haunted_Witch/Haunted-Witch.ttf')",
      label: 'Haunted Witch',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Indie_Flower/IndieFlower-Regular.ttf')",
      label: 'Indie Flower',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Inter/Inter-Regular.ttf')",
      label: 'Inter',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Kavoon/Kavoon-Regular.ttf')",
      label: 'Kavoon',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Lato/Lato-Regular.ttf')",
      label: 'Lato',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Lobster/Lobster-Regular.ttf')",
      label: 'Lobster',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Luckiest_Guy/LuckiestGuy-Regular.ttf')",
      label: 'Luckiest Guy',
      lineHeightFactor: 1.1,
    },
    {
      url: "url('/fonts/Merriweather/Merriweather-Regular.ttf')",
      label: 'Merriweather',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Monoton/Monoton-Regular.ttf')",
      label: 'Monoton',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Montserrat/Montserrat-Regular.ttf')",
      label: 'Montserrat',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Montserrat/Montserrat-Black.ttf')",
      label: 'Montserrat Black',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Mukta/Mukta-Regular.ttf')",
      label: 'Mukta',
      lineHeightFactor: 1.6875,
    },
    // {
    //   url: "url('/fonts/Nabla/Nabla-Regular.ttf')",
    //   label: 'Nabla',
    //   lineHeightFactor: 1.625,
    // },
    {
      url: "url('/fonts/Nosifer/Nosifer-Regular.ttf')",
      label: 'Nosifer',
      lineHeightFactor: 1.6875,
    },
    {
      url: "url('/fonts/Noir_Pro/NoirPro-Heavy.ttf')",
      label: 'Noir Pro',
      lineHeightFactor: 1.6875,
    },
    {
      url: "url('/fonts/Noto_Sans/NotoSans-Regular.ttf')",
      label: 'Noto Sans',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Noto_Sans/NotoSans-Black.ttf')",
      label: 'Noto Black',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Noto_Sans/NotoSans-Bold.ttf')",
      label: 'Noto Bold',
      lineHeightFactor: 1.375,
    },
    {
      url: "url('/fonts/Oswald/Oswald-Regular.ttf')",
      label: 'Oswald',
      lineHeightFactor: 1.5,
    },
    {
      url: "url('/fonts/Paytone_One/PaytoneOne-Regular.ttf')",
      label: 'Paytone One',
      lineHeightFactor: 1.4375,
    },
    {
      url: "url('/fonts/Permanent_Marker/PermanentMarker-Regular.ttf')",
      label: 'Permanent Marker',
      lineHeightFactor: 1.4375,
    },
    {
      url: "url('/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf')",
      label: 'Playfair Display',
      lineHeightFactor: 1.3125,
    },
    {
      url: "url('/fonts/Poppins/Poppins-Regular.ttf')",
      label: 'Poppins',
      lineHeightFactor: 1.5625,
    },
    {
      url: "url('/fonts/Press_Start_2P/PressStart2P-Regular.ttf')",
      label: 'Press_Start_2P',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Raleway/Raleway-Regular.ttf')",
      label: 'Raleway',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Rammetto_One/RammettoOne-Regular.ttf')",
      label: 'Rammetto One',
      lineHeightFactor: 1.6875,
    },
    {
      url: "url('/fonts/Metropolis/Metropolis-Regular.otf')",
      label: 'Metropolis',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Metropolis/Metropolis-Bold.otf')",
      label: 'Metropolis-bold',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Metropolis/Metropolis-ExtraBold.otf')",
      label: 'Metropolis-extra-bold',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/MinePlayer/MinePlayer-Regular.ttf')",
      label: 'MinePlayer',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Red_Hat_Display/RedHatDisplay-Regular.ttf')",
      label: 'Red Hat Display',
      lineHeightFactor: 1.3125,
    },
    {
      url: "url('/fonts/Righteous/Righteous-Regular.ttf')",
      label: 'Righteous',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Roboto_Semi_Condensed/Roboto_SemiCondensed-Black.ttf')",
      label: 'Roboto Semi Condensed Black',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Roboto_Semi_Condensed/Roboto_SemiCondensed-ExtraBoldItalic.ttf')",
      label: 'Roboto Semi Condensed Extra Bold Italic',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf')",
      label: 'Roboto Condensed',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Roboto_Mono/RobotoMono-Regular.ttf')",
      label: 'Roboto Mono',
      lineHeightFactor: 1.3125,
    },
    {
      url: "url('/fonts/Roboto_Slab/RobotoSlab-Regular.ttf')",
      label: 'Roboto Slab',
      lineHeightFactor: 1.3125,
    },
    {
      url: "url('/fonts/Roboto/Roboto-Regular.ttf')",
      label: 'Roboto',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Rubik/Rubik-Bold.ttf')",
      label: 'Rubik Bold',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Rubik_Glitch/RubikGlitch-Regular.ttf')",
      label: 'Rubik Glitch',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Rubik_Mono_One/RubikMonoOne-Regular.ttf')",
      label: 'Rubik Mono One',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Sigmar_One/SigmarOne-Regular.ttf')",
      label: 'Sigmar One',
      lineHeightFactor: 1.625,
    },
    {
      url: "url('/fonts/Silkscreen/Silkscreen-Regular.ttf')",
      label: 'Silkscreen',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Smokum/Smokum-Regular.ttf')",
      label: 'Smokum',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Syncopate/Syncopate-Regular.ttf')",
      label: 'Syncopate',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Stinky_Slime/Stinky-Slime.ttf')",
      label: 'Stinky Slime',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Titan_One/TitanOne-Regular.ttf')",
      label: 'Titan One',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Ultra/Ultra-Regular.ttf')",
      label: 'Ultra',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Vast_Shadow/VastShadow-Regular.ttf')",
      label: 'Vast Shadow',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/VT323/VT323-Regular.ttf')",
      label: 'VT323',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Wallpoet/Wallpoet-Regular.ttf')",
      label: 'Wallpoet',
      lineHeightFactor: 1,
    },
    {
      url: "url('/fonts/Work_Sans/WorkSans-Regular.ttf')",
      label: 'Work Sans',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Zilla_Slab_Highlight/ZillaSlabHighlight-Regular.ttf')",
      label: 'Zilla Slab Highlight',
      lineHeightFactor: 1.1875,
    },
    {
      url: "url('/fonts/Staatliches/Staatliches-Regular.woff')",
      label: 'Staatliches',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/ShadowIntoLight/ShadowsIntoLight-Regular.ttf')",
      label: 'Shadows Into Light',
      lineHeightFactor: 1.625,
    },
    {
      url: "url('/fonts/ElsieSwashCaps/ElsieSwashCaps-Regular.ttf')",
      label: 'Elsie Swash Caps',
      lineHeightFactor: 1.125,
    },
    {
      url: "url('/fonts/ArchivoBlack/ArchivoBlack-Regular.ttf')",
      label: 'Archivo Black',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Pacifico/Pacifico-Regular.ttf')",
      label: 'Pacifico',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Vermillion/Vermillion.ttf')",
      label: 'Vermillion',
      lineHeightFactor: 1.25,
    },
    {
      url: "url('/fonts/Woodennickelblack/WoodenNickelNF.ttf')",
      label: 'WoodenNickelNF',
      lineHeightFactor: 1.25,
    },
    {
      label: 'DynaPuff',
      lineHeightFactor: 1.25,
      url: "url('/fonts/DynaPuff/DynaPuff-Bold.ttf')",
    },
    {
      label: 'Carter One',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Carter_One/CarterOne.ttf')",
    },
    {
      label: 'Christmas Holiday',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Christmas/ChristmasHoliday.otf')",
    },
    {
      label: 'Christmas',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Christmas/Christmas.otf')",
    },
    {
      label: 'Sovereign',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Sovereign/Sovereign.ttf')",
    },
    {
      label: 'Francy',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Francy/Francy.ttf')",
    },
    {
      label: 'Spot',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Spot/Spot-Normal.ttf')",
    },
    {
      label: 'Daddy Ranch',
      lineHeightFactor: 1.25,
      url: "url('/fonts/DaddyRanch/DaddyRanch-Regular.ttf')",
    },
    {
      label: 'Pedlefun',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Pedlefun/PEDLEFUN.ttf')",
    },
    {
      label: 'Fortnite',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Fortnite/Fortnite.ttf')",
    },
    {
      label: 'Beaufort',
      lineHeightFactor: 1.25,
      url: "url('/fonts/BeaufortforLOL/BeaufortforLOL-BoldItalic.ttf')",
    },
    {
      label: 'Pulse',
      lineHeightFactor: 1.25,
      url: "url('/fonts/PulseRounded/Pulse Rounded Regular.otf')",
    },
    {
      label: 'Racing Sans One',
      url: "url('/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf')",
      lineHeightFactor: 3,
    },
    {
      label: 'Komika Axis',
      lineHeightFactor: 1.25,
      url: "url('/fonts/KomikaAxis/KOMIKAX_.ttf')",
    },
    {
      label: 'Unbounded',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Unbounded/Unbounded-VariableFont_wght.ttf')",
    },
    {
      label: 'Bevan',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Bevan/Bevan-Regular.ttf')",
    },
    {
      label: 'Acme',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Acme/Acme-Regular.ttf')",
    },
    {
      label: 'Space Grotesk',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf')",
    },
    {
      label: 'Cherry Bomb',
      lineHeightFactor: 1.25,
      url: "url('/fonts/Cherry_Bomb/CherryBombOne-Regular.ttf')",
    },
    {
      label: 'TikTok',
      lineHeightFactor: 1.6875,
      url: "url('/fonts/TikTokFont/TikTokDisplayFont-Regular.ttf')",
    },
  ],
} as const

type Fonts = (typeof fontsData)['fonts']

export type FontLabel = Fonts[number]['label']

export type Font = {
  url: string
  label: FontLabel
  lineHeightFactor: number
  preloaded?: boolean
}
