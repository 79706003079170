<script setup lang="ts">
import CaptionSentenceWithEmojis from '@/components/Captions/CaptionSentenceWithEmojis.vue'
import type { CaptionStyleDefinition, StoredCaption } from '@/components/Captions/captionTypes'
import { useCaptionCss } from '@/components/Captions/useCaptionCss'
import { v4 } from 'uuid'
import { computed, ref, toRef, watch } from 'vue'
import type { CaptionPreset } from './v3/CaptionPreset'

const props = defineProps<{
  caption: StoredCaption
  captionStyleDefinition: CaptionStyleDefinition
  preset?: CaptionPreset
  editable?: boolean
  isRenderer?: boolean
}>()

const emit = defineEmits(['input', 'blur'])
const input = ref<{ text: string, color?: string }[]>(props.caption.words)

const key = ref(v4())
watch(() => props.caption.words, (words) => {
  setTimeout(() => {
    if (props.editable) {
      key.value = v4()
      input.value = words
    }
  }, 0)
}, { immediate: true, deep: true })

function onInput() {
  input.value = [{ text: editableText.value?.innerText ?? '', color: undefined }];
  emit('input', { text: editableText.value?.innerText ?? '', color: undefined });
}

const editableText = ref<HTMLElement | null>(null)
watch(
  () => props.editable,
  async () => {

    if (!props.editable) {
      return
    }

    if (!editableText.value) {
      return
    }

    const fakeInput = document.createElement('input')
    fakeInput.setAttribute('type', 'text')
    fakeInput.style.position = 'absolute'
    fakeInput.style.opacity = 0
    fakeInput.style.height = 0
    fakeInput.style.fontSize = '16px'

    document.body.prepend(fakeInput)

    fakeInput.focus()

    setTimeout(() => {

      if (editableText.value) {
        window.getSelection()?.selectAllChildren(editableText.value);
        editableText.value?.focus();
      }

      setTimeout(() => {
        fakeInput.remove()
      }, 0);
    }, 0);
  })

const { getGradient, getStyles, getBase64, classes } = useCaptionCss(toRef(props, 'captionStyleDefinition'))
const hasGradient = computed(() => {
  return getGradient(props.caption.color) || props.caption.words.some((w) => getGradient(w.color))
})

function onPaste(e: ClipboardEvent) {
  if (props.editable) {
    e.preventDefault()
    const text = e.clipboardData?.getData('text/plain') ?? ''
    document.execCommand('insertText', false, text)
  }
}
</script>

<template>
  <div
    class="flex items-center justify-center relative w-full h-full"
    :style="{
      fontFamily: captionStyleDefinition.fontFamily,
      lineHeight: captionStyleDefinition.fontSize.lineHeight || 1.2,
    }"
  >
    <template v-if="false">
      <!-- 
        `editableText` must be a <span>. If it is a <p>, the browser might insert HTML tags such as 
        <br />. Unexpected HTML tags might interfere with styling as well as the component lifecycle.
      -->
    </template>
    <span
      ref="editableText"
      class="text !select-text"
      :key="key"
      :contenteditable="editable"
      @input="onInput"
      @blur="$emit('blur', $event)"
      @paste="onPaste"
      @keydown.esc="$emit('blur', $event)"
    >
      <CaptionSentenceWithEmojis
        :caption="caption"
        :caption-style-definition="captionStyleDefinition"
        :emoji-position="props.caption.emojis && props.captionStyleDefinition.effects.emoji?.location"
        :get-styles="(color) => getStyles(color ?? caption.color)"
        :class="[classes, props.isRenderer ? 'text-renderer' : '']"
        :emojis="caption.emojis" 
        :words="props.caption.words"
      />
    </span>
    <p
      v-if="hasGradient"
      class="text text-gradient absolute"
      :style="(props.isRenderer ? {} : { '--gradient': getGradient(caption.color) ?? getBase64(caption.color) })"
    >
      <CaptionSentenceWithEmojis 
        :caption="caption"
        :caption-style-definition="captionStyleDefinition"
        :emoji-position="props.caption.emojis && props.captionStyleDefinition.effects.emoji?.location"
        :class="['text-gradient', classes, props.isRenderer ? 'text-renderer' : '']"
        :get-styles="(color) => color ? ({ '--gradient': getGradient(color) ?? getBase64(color) }) : { '--gradient': 'none' }"
        :emojis="caption.emojis" 
        :words="input" 
      />
    </p>
  </div>
</template>

<style scoped>
.text {
  display: inline;
  font-weight: 400;
  color: var(--color);
  --border-size: 3px;
  text-shadow: var(--text-border, 0 0 0 transparent), var(--text-shadow, 0 0 0 transparent);
  filter: drop-shadow(var(--drop-shadow));
}

.text-gradient {
    background-image: var(--gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
}
</style>
